import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { MultiValue } from "react-select";
import toast from "react-hot-toast";
import { RootState } from "store";
import { downloadSelectedMessages } from "@services";
import Translation from "@common/Translation";
import ActionModal from "./ActionModal";
import { Message } from "../../types";
import { handleFileDownload } from "../../utils/download";

interface MultiSelectToolbarProps {
  messages: Message[];
  selectedCount: number;
  tags: { label: string; value: string }[];
  onDelete: () => void;
  onTag: (tagNames: string[]) => void;
  onReplace: () => void;
  onSelectAll: (selectAll: boolean) => void;
}

const MultiSelectToolbar: React.FC<MultiSelectToolbarProps> = ({
  messages,
  selectedCount,
  tags,
  onDelete,
  onTag,
  onReplace,
  onSelectAll,
}) => {
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );

  const [modal, setModal] = useState<{
    action: "replace" | "tag" | "download" | "delete" | null;
    loading: boolean;
  }>({ action: null, loading: false });

  const [selectedTags, setSelectedTags] = useState<
    { label: string; value: string }[]
  >([]);
  const [controller, setController] = useState<AbortController | null>(null);

  const getSelectedMessageIds = () =>
    messages.filter((msg) => msg.selected).map((msg) => msg.id);

  const downloadSelectedFiles = async (
    selectedMessages: string[],
    signal: AbortSignal,
  ) => {
    setModal({ action: "download", loading: true });

    try {
      const payload = { recordedMessageIds: selectedMessages };
      const response = await downloadSelectedMessages(payload, signal);
      handleFileDownload(response, "SelectedMessages");
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === "CanceledError") {
          console.warn("Download canceled by user");
        } else {
          console.error("Error downloading selected files:", error.message);
          toast.error(
            translations.An_error_occurred_while_processing_your_request,
          );
        }
      } else {
        console.error("Unknown error occurred:", error);
        toast.error(
          translations.An_error_occurred_while_processing_your_request,
        );
      }
    } finally {
      setModal({ action: null, loading: false });
    }
  };

  const handleDownloadSelected = useCallback(() => {
    const newController = new AbortController();
    setController(newController);
    setModal({ action: "download", loading: false });
  }, [messages]);

  const handleDownloadConfirm = useCallback(() => {
    if (controller) {
      downloadSelectedFiles(getSelectedMessageIds(), controller.signal);
    }
  }, [controller]);

  const handleDownloadCancel = useCallback(() => {
    if (controller) {
      controller.abort();
      setController(null);
    }
    setModal({ action: null, loading: false });
  }, [controller]);

  const handleTagChange = (
    selected: MultiValue<{ label: string; value: string }>,
  ) => {
    setSelectedTags(selected as { label: string; value: string }[]);
  };

  const handleConfirm = async () => {
    setModal((prev) => ({ ...prev, loading: true }));

    try {
      if (modal.action === "download") await handleDownloadConfirm();
      if (modal.action === "delete") await onDelete();
      if (modal.action === "tag") {
        const tagNames = selectedTags.map((tag) => tag.value);
        if (tagNames.length > 0) {
          await onTag(tagNames);
        }
      }
      if (modal.action === "replace") await onReplace();
    } finally {
      setModal({ action: null, loading: false });
    }
  };

  const modalDetails = {
    replace: {
      title: translations.replace_message_title,
      action: translations.replace,
      content: (
        <p>
          <Translation translate="replace_message_description" />
        </p>
      ),
    },
    tag: {
      title: translations.MYTD_tagMsgsLbl,
      action: translations.MYTD_tagLbl,
      content: (
        <div className="form-group">
          <CreatableSelect
            isMulti
            isClearable
            options={tags}
            value={selectedTags}
            onChange={handleTagChange}
            placeholder={translations.MYTD_tagLbl}
            classNamePrefix="custom-select"
            formatCreateLabel={(inputValue) =>
              `${translations.MYTD_addLbl} "${inputValue}"`
            }
          />
        </div>
      ),
    },
    download: {
      title: translations.MYTD_downloadMsgsLbl,
      action: translations.MYTD_downloadLbl,
      content: (
        <p>
          <Translation translate="MYTD_takesAWhileMsg" />
        </p>
      ),
    },
    delete: {
      title: translations.MYTD_deleteMsgsLbl,
      action: translations.MYTD_deleteLbl,
      content: (
        <p>
          <Translation translate="MYTD_deleteMsgWarnTxt" />
          <br />
          <em>
            <Translation translate="MYTD_actionWarnTxt" />
          </em>
        </p>
      ),
    },
  };

  const currentModal = modal.action ? modalDetails[modal.action] : null;

  return (
    <>
      <thead>
        <tr className="multi-select-toolbar">
          <th className="col-xs-3">
            <input
              type="checkbox"
              checked
              onChange={() => onSelectAll(false)}
            />
            {selectedCount} <Translation translate="selected" />
          </th>
          <th className="col-xs-1 bulk-actions" colSpan={3}>
            <button
              className="bulk-control replace-title"
              onClick={() => setModal({ action: "replace", loading: false })}
              title={translations.replace_titles}
            />
          </th>
          <th className="col-xs-1 bulk-actions">
            <button
              className="bulk-control tag"
              onClick={() => setModal({ action: "tag", loading: false })}
              title={translations.MYTD_tagSelectedLbl}
            />
          </th>
          <th className="col-xs-1 bulk-actions">
            <button
              className="bulk-control download"
              onClick={handleDownloadSelected}
              title={translations.MYTD_downloadSelectedTxt}
            />
            <button
              className="bulk-control delete"
              onClick={() => setModal({ action: "delete", loading: false })}
              title={translations.MYTD_deleteSelectedTxt}
            />
          </th>
        </tr>
      </thead>

      {/* Modal */}
      {currentModal && (
        <ActionModal
          show={!!modal.action}
          title={currentModal.title}
          body={currentModal.content}
          confirmText={currentModal.action}
          cancelText={translations.MYTD_cancelBtnTxt}
          onConfirm={handleConfirm}
          onCancel={
            modal.action === "download"
              ? handleDownloadCancel
              : () => setModal({ action: null, loading: false })
          }
          loading={modal.loading}
        />
      )}
    </>
  );
};

export default MultiSelectToolbar;
