import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { getStoreOfferings, getLanguages } from "@services";
import Breadcrumb from "@common/Breadcrumb";
import toast from "react-hot-toast";
import {
  setInitialLangFilter,
  pathByName,
  applyFilter,
} from "@helpers/utilities";

const StoreHome = () => {
  const { translations } = useSelector((state) => state.translations);
  const { languageCode } = useSelector((state) => state.languageData);
  const [storeData, setStoreData] = useState({});
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getStoreOfferings(languageCode);
        setStoreData(data[0]);
      } catch (error) {
        toast.error(
          translations.An_error_occurred_while_processing_your_request,
        );
      }
    };
    fetchData();
  }, [languageCode]);

  useEffect(() => {
    if (storeData?.products) {
      const supportedLangs = getLanguages(storeData.products);
      setLanguageOptions(supportedLangs);

      // Pre-select default languages
      const defaultLanguage = navigator.language;
      const initialLanguage = setInitialLangFilter(defaultLanguage);
      const preSelectedOptions = supportedLangs.filter((option) =>
        initialLanguage.some((lang) => lang === option.value),
      );

      if (preSelectedOptions) {
        setSelectedLanguages(preSelectedOptions);
        const selectedValues = preSelectedOptions.map((option) => option.value);
        // Apply initial filter
        const filtered = applyFilter(selectedValues, storeData.products);
        setFilteredProducts(filtered);
      }
    }
  }, [storeData]);

  const handleLanguageChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedLanguages(selectedOptions);

    // Apply filter
    const filtered = applyFilter(selectedValues, storeData.products);
    setFilteredProducts(filtered);
  };

  return (
    <>
      {translations && (
        <>
          <div className="container-full">
            <div className="container myStuff-header">
              <h1>{translations.Store}</h1>
              <Breadcrumb items={[{ label: translations.Store }]} />
            </div>
          </div>
          <div className="container">
            <div className="row margin-top-lg">
              <div className="col-xs-12">
                <h5 className="ossb" style={{ marginBottom: "3px" }}>
                  {translations.Show_more_langs}
                </h5>
                <Select
                  isMulti
                  options={languageOptions}
                  value={selectedLanguages}
                  onChange={handleLanguageChange}
                  placeholder={translations.Show_more_langs}
                />
              </div>
            </div>
          </div>
          <div className="container fade-block fade-in">
            {filteredProducts.length > 0 && (
              <div className="row">
                <div className="col-xs-12">
                  <h2>{storeData.categoryTitle}</h2>
                  <h5 className="ossb">{storeData.categoryTagLine}</h5>
                  <h5>{storeData.categoryDescription}</h5>
                </div>
              </div>
            )}
            <div className="row">
              <div className="products-container fade-block fade-in">
                {filteredProducts.length > 0 ? (
                  <div id="filtered-content">
                    {filteredProducts.map((product) => (
                      <div className="col-sm-6 col-md-4" key={product.title}>
                        <a
                          href={`/store/${pathByName(product.productId, "store")}`}
                          className="product-tile-link"
                        >
                          <div className="products">
                            <div className="img-thumbnail-container">
                              <img
                                src={`/assets/images${product.imageUrl}`}
                                alt="Product image"
                              />
                            </div>
                            <div className="details">
                              <h5 className="truncate-single">
                                {product.title}
                              </h5>
                              <div className="part-numbers">
                                {product.shortDescription}
                              </div>
                            </div>
                            <div className="details2">
                              <div className="action">
                                <span className="get-software-link">
                                  {product.urlLabel}
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="no-products">{translations.No_products}</div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default StoreHome;
