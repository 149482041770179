import React from "react";
import Select, { SingleValue } from "react-select";
import { Filters, OptionType, VerifiedOptionType } from "../types";
import { RootState } from "store";
import { useSelector } from "react-redux";

interface FiltersDropdownProps {
  handleFilterChange: (updatedFilters: Partial<Filters>) => void;
  filters: Filters;
}

const FiltersDropdown: React.FC<FiltersDropdownProps> = ({
  handleFilterChange,
  filters,
}) => {
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );

  const filterOptions: VerifiedOptionType[] = [
    { value: null, label: translations.MYTD_showAllLbl },
    // TODO: No translations available for "Show Verified" and "Show Unverified"
    { value: true, label: "Show Verified" },
    { value: false, label: "Show Unverified" },
  ];

  const sortOptions: OptionType[] = [
    { value: "label", label: `${translations.MYTD_titleTxt} ↓` },
    { value: "-label", label: `${translations.MYTD_titleTxt} ↑` },
    { value: "text", label: `${translations.MYTD_MBListText} ↓` },
    { value: "-text", label: `${translations.MYTD_MBListText} ↑` },
    { value: "length", label: `${translations.Length} ↓` },
    { value: "-length", label: `${translations.Length} ↑` },
    { value: "created", label: `${translations.Created} ↓` },
    { value: "-created", label: `${translations.Created} ↑` },
  ];

  return (
    <div className="dropdown-filters">
      {/* Sort Dropdown */}
      <Select
        options={sortOptions}
        value={sortOptions.find((option) => option.value === filters.sort)}
        placeholder={translations.Sort_by}
        onChange={(selectedOption) => {
          const option = selectedOption as SingleValue<OptionType>;
          if (option && option.value) {
            handleFilterChange({ sort: option.value });
          }
        }}
        classNamePrefix="custom-select"
        isSearchable={false}
      />
      {/* Verified filter dropdown */}
      <Select
        options={filterOptions}
        value={filterOptions.find(
          (option) => option.value === filters.filter.verified,
        )}
        placeholder="Show All"
        onChange={(selectedOption) => {
          const option = selectedOption as SingleValue<VerifiedOptionType>;
          if (option) {
            handleFilterChange({ filter: { verified: option.value } });
          }
        }}
        classNamePrefix="custom-select"
        isSearchable={false}
      />
    </div>
  );
};

export default FiltersDropdown;
