import React from "react";
import { Modal } from "react-bootstrap";
import Translation from "@common/Translation";
import { FileWithMetadata } from "types";

interface UploadStatusModalProps {
  showModal: boolean;
  fileQueue: FileWithMetadata[];
  queueLength: number;
  queueTotalSize: number;
  queueTotalFileSize: number;
  queueTotalFileSizeProgress: number;
  setShowUploadStatusModal: (show: boolean) => void;
}

const UploadStatusModal: React.FC<UploadStatusModalProps> = ({
  showModal,
  fileQueue,
  queueLength,
  queueTotalSize,
  queueTotalFileSize,
  queueTotalFileSizeProgress,
  setShowUploadStatusModal,
}) => {
  const progress =
    queueTotalFileSize > 0
      ? Math.floor((queueTotalFileSizeProgress / queueTotalFileSize) * 100)
      : 0;

  return (
    <Modal
      show={showModal}
      backdrop="static"
      className={`upload-status text-center ${
        queueLength > 0 ? "upload-status-processing" : "upload-status-complete"
      } ${showModal ? "in" : ""}`}
    >
      <Modal.Header>
        {queueLength > 0 ? (
          <h2>
            {progress}% <Translation translate="MYTD_MBUploadComplete" />
          </h2>
        ) : (
          <>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => {
                setShowUploadStatusModal(false);
                window.location.reload();
              }}
              style={{ top: "35px", left: "30px", position: "relative" }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h2>
              <span className="icon icon-complete"></span>
              <Translation translate="MYTD_uploadCompleteMsg" />
            </h2>
          </>
        )}
      </Modal.Header>

      <Modal.Body>
        {queueLength > 0 ? (
          <>
            <p>
              <strong>
                {queueTotalSize - queueLength}{" "}
                <Translation translate="MYTD_ofLabel" /> {queueTotalSize}{" "}
                {queueTotalSize === 1 ? (
                  <Translation translate="File" />
                ) : (
                  <Translation translate="files" />
                )}{" "}
                <Translation translate="MYTD_uploadedTxt" />
              </strong>
            </p>
            <p>
              <em>
                <Translation translate="MYTD_leavepageUploadWarnTxt1" />
                <br />
                <Translation translate="MYTD_leavepageUploadWarnTxt2" />
              </em>
            </p>
          </>
        ) : (
          <>
            <p>
              <strong>
                {fileQueue.filter((file) => file.status === "completed").length}{" "}
                {fileQueue.filter((file) => file.status === "completed")
                  .length === 1 ? (
                  <Translation translate="File" />
                ) : (
                  <Translation translate="files" />
                )}{" "}
                <Translation translate="MYTD_MBUploadedSuccess" />
              </strong>
            </p>
            {fileQueue.filter((file) => file.status === "failed").length >
              0 && (
              <p>
                <strong>
                  <Translation translate="MYTD_filesCantGoLbl" />
                </strong>
                {fileQueue
                  .filter((file) => file.status === "failed")
                  .map((file, index) => (
                    <div key={index}>{file.file.name}</div>
                  ))}
              </p>
            )}
            <p>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.reload();
                }}
              >
                <Translation translate="MYTD_viewMyMsgsLbl" />
              </a>
            </p>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UploadStatusModal;
