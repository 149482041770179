import { React } from "react";
import RawHTML from "@common/RawHTML/Index";
import ProductBuyButton from "./Common/BuyButton";
import { useSelector } from "react-redux";

import { redirectToLogin } from "@services";
import BuyButton from "./Common/BuyButton";
import FastSpringProvider from "@common/FastSpring/FastSpringProvider";

const OneTimeSinglePurchase = ({
  oneTimeSinglePurchase,
  translations,
  product,
}) => {
  const { isAuthenticated, userDetail } = useSelector((state) => state.user);

  const handleCheckoutPodd = () => {
    if (window.fastspring) {
      window.fastspring.builder.checkout({
        products: [{ path: "speech-case-with-snap-podd" }],
      });
    }
  };
  const handleCheckoutGateway = () => {
    if (window.fastspring) {
      window.fastspring.builder.checkout({
        products: [{ path: "speech-case-with-snap-gateway" }],
      });
    }
  };

  if (product === "GazeViewer")
    return (
      <>
        {oneTimeSinglePurchase.applications.map((app, index) => (
          <div
            id="shopNow"
            className={
              product === "GazeViewer" ? "container" : "container-full"
            }
            key={`${app.type}-${index}`}
          >
            <div className="container text-center">
              <div className="row">
                <div className="col-sm-8 col-sm-push-2">
                  <h2>{app.type}</h2>
                  <RawHTML htmlContent={app.description} />
                </div>
              </div>
              <div className="row jump-links">
                {app.apps.map((insideApp, index) => (
                  <div
                    className="col-sm-6 col-sm-push-3 col-md-4 col-md-push-4"
                    key={index}
                  >
                    <div className="well-product-link">
                      <div className="match_desc">
                        <div className="hidden-xs icon icon-store icon-68"></div>
                        <h3>{insideApp.title}</h3>
                        <p>{insideApp.description}</p>
                      </div>
                      <div className="match_cta pricing">
                        <hr />
                        <h3>{insideApp.price}</h3>
                        <h4>{insideApp.priceNoteHeader}</h4>
                        <ProductBuyButton
                          text={translations.Buy_Now}
                          className="btn btn-lg btn-primary"
                          link={insideApp.links}
                          data-testid="buy-button"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </>
    );
  else if (product === "SpeechCasePodd") {
    if (isAuthenticated) {
      return (
        <div className="container-full">
          {userDetail && (
            <FastSpringProvider
              user={userDetail}
              storefrontLink={
                oneTimeSinglePurchase?.applications[0]?.apps[0]?.links
              }
              productPath={
                product === "SpeechCasePodd"
                  ? "speech-case-with-snap-podd"
                  : product === "SpeechCaseGateway"
                    ? "speech-case-with-snap-gateway"
                    : "snap-core-first-speech"
              }
            />
          )}

          <div className="container text-center">
            <h2>{oneTimeSinglePurchase?.applications[0]?.type}</h2>
            <div className="row">
              <div className="col-md-4">
                <div className="well well-match jump-links text-center">
                  <div className="icon icon-active-account-status icon-68 data-icon"></div>
                  <h3 className="trial-well-header" style={{ height: "20px" }}>
                    {translations.Step_Number?.replace("{0}", 1)} {"-"}{" "}
                    {oneTimeSinglePurchase?.applications[0]?.apps[0]?.title}
                  </h3>
                  <div className="trial-well-body" style={{ height: "45px" }}>
                    <button
                      className="btn btn-primary btn-lg"
                      onClick={handleCheckoutPodd}
                      data-fsc-action="Add, Checkout"
                    >
                      {translations.Checkout}
                    </button>
                  </div>
                  <div className="trial-well-footer" style={{ height: "50px" }}>
                    <hr></hr>
                    <p>{translations.Snap_PODD_not_claimed}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="well well-match jump-links text-center">
                  <div className="icon icon-download-installer icon-68 data-icon"></div>
                  <h3 className="trial-well-header" style={{ height: "20px" }}>
                    {translations.Step_Number?.replace("{0}", 2)} {"-"}{" "}
                    {translations.Download}
                  </h3>
                  <div className="trial-well-body" style={{ height: "45px" }}>
                    <a
                      href={
                        oneTimeSinglePurchase?.applications[0]?.apps[1]?.links
                      }
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-lg btn-apple"
                    >
                      <img
                        src="/assets/images/btn/badge-download-on-the-app-store.svg"
                        alt={translations.Download_on_the_App_Store}
                        className="margin-right-none"
                      />
                    </a>
                  </div>
                  <div className="trial-well-footer" style={{ height: "50px" }}>
                    <hr />
                    <p>{translations.If_snap_is_installed_go_to_next_step}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="well well-match jump-links text-center">
                  <div className="icon icon-member icon-68 data-icon"></div>
                  <h3 className="trial-well-header" style={{ height: "20px" }}>
                    {translations.Step_Number?.replace("{0}", 3)} {"-"}{" "}
                    {translations.Log_in}
                  </h3>
                  <div className="trial-well-body" style={{ height: "45px" }}>
                    <p>
                      {
                        translations.Log_in_to_myTobiiDynavox_during_app_start_up
                      }
                    </p>
                  </div>
                  <div className="trial-well-footer" style={{ height: "50px" }}>
                    <hr />
                    <p>
                      {
                        translations.Follow_the_instructions_in_the_app_to_begin_using_your_pageset
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      redirectToLogin();
    }
  } else if (product === "SpeechCaseGateway") {
    if (isAuthenticated) {
      return (
        <div className="container-full">
          {userDetail && (
            <FastSpringProvider
              user={userDetail}
              storefrontLink={
                oneTimeSinglePurchase?.applications[0]?.apps[0]?.links
              }
              productPath={
                product === "SpeechCaseGateway"
                  ? "speech-case-with-snap-gateway"
                  : product === "SpeechCasePodd"
                    ? "speech-case-with-snap-podd"
                    : "snap-core-first-speech"
              }
            />
          )}

          <div className="container text-center">
            <h2>{oneTimeSinglePurchase?.applications[0]?.title}</h2>
            <div className="row">
              <div className="col-md-4">
                <div className="well well-match jump-links text-center">
                  <div className="icon icon-active-account-status icon-68 data-icon"></div>
                  <h3 className="trial-well-header" style={{ height: "20px" }}>
                    {translations.Step_Number?.replace("{0}", 1)} {"-"}{" "}
                    {oneTimeSinglePurchase?.applications[0]?.apps[0]?.title}
                  </h3>
                  <div className="trial-well-body" style={{ height: "45px" }}>
                    <button
                      className="btn btn-primary btn-lg"
                      onClick={handleCheckoutGateway}
                      data-fsc-action="Add, Checkout"
                    >
                      {translations.Checkout}
                    </button>
                  </div>
                  <div className="trial-well-footer" style={{ height: "50px" }}>
                    <hr></hr>
                    <p>{translations.Snap_Gateway_not_claimed}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="well well-match jump-links text-center">
                  <div className="icon icon-download-installer icon-68 data-icon"></div>
                  <h3 className="trial-well-header" style={{ height: "20px" }}>
                    {translations.Step_Number?.replace("{0}", 2)} {"-"}{" "}
                    {translations.Download}
                  </h3>
                  <div className="trial-well-body" style={{ height: "45px" }}>
                    <a
                      href={
                        oneTimeSinglePurchase?.applications[0]?.apps[1]?.links
                      }
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-lg btn-apple"
                    >
                      <img
                        src="/assets/images/btn/badge-download-on-the-app-store.svg"
                        alt={translations.Download_on_the_App_Store}
                        className="margin-right-none"
                      />
                    </a>
                  </div>
                  <div className="trial-well-footer" style={{ height: "50px" }}>
                    <hr />
                    <p>{translations.If_snap_is_installed_go_to_next_step}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="well well-match jump-links text-center">
                  <div className="icon icon-member icon-68 data-icon"></div>
                  <h3 className="trial-well-header" style={{ height: "20px" }}>
                    {translations.Step_Number?.replace("{0}", 3)} {"-"}{" "}
                    {translations.Log_in}
                  </h3>
                  <div className="trial-well-body" style={{ height: "45px" }}>
                    <p>
                      {
                        translations.Log_in_to_myTobiiDynavox_during_app_start_up
                      }
                    </p>
                  </div>
                  <div className="trial-well-footer" style={{ height: "50px" }}>
                    <hr />
                    <p>
                      {
                        translations.Follow_the_instructions_in_the_app_to_begin_using_your_pageset
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      redirectToLogin();
    }
  } else
    return (
      <div className="container-full" id="shopNow">
        <div className="container text-center">
          <h2>{oneTimeSinglePurchase?.applications[0]?.type}</h2>
          <p>{oneTimeSinglePurchase?.applications[0]?.description}</p>
          <p className="note">{oneTimeSinglePurchase?.headerNote}</p>
          <div className="row">
            <div className="col-md-6">
              <div
                className="well jump-links text-center"
                style={{ height: "290px" }}
              >
                <div className="icon icon-store icon-68 data-icon"></div>
                <h3>
                  {translations.Step_Number?.replace("{0}", 1)} {"-"}{" "}
                  {translations.Buy_a_License_Redeem_a_Code}{" "}
                </h3>
                <BuyButton
                  text={translations.Buy_or_Redeem_Now}
                  className="btn btn-lg btn-primary"
                  link={oneTimeSinglePurchase?.applications[0]?.apps[0]?.links}
                ></BuyButton>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="well jump-links text-center"
                style={{ height: "290px" }}
              >
                <div className="icon icon-download-installer icon-68 data-icon"></div>
                <h3>
                  {translations.Step_Number?.replace("{0}", 2)} {"-"}{" "}
                  {translations.Download_App}
                </h3>
                <a
                  href={oneTimeSinglePurchase?.applications[0]?.apps[1]?.links}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-lg btn-windows"
                  data-testid="download-button"
                >
                  <img
                    src="/assets/images/btn/badge-download-for-windows.svg"
                    alt={
                      oneTimeSinglePurchase?.applications[0]?.apps[1]
                        ?.availability
                    }
                    className="margin-right-none"
                  />
                </a>
                <p>
                  {translations.Log_in_to_myTobiiDynavox_during_app_start_up}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default OneTimeSinglePurchase;
