import { createReducer } from "@reduxjs/toolkit";
import {
  setUserDataFailure,
  setUserDetails,
  setAuthentication,
  setIsAdmin,
} from "../actions";

const initialState = {
  userDetail: null,
  isAuthenticated: false,
  isAdmin: false,
};

const userDetailsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUserDetails, (state, action) => {
      state.userDetail = action.payload;
    })
    .addCase(setUserDataFailure, (state, action) => {
      state.error = action.payload;
    })
    .addCase(setAuthentication, (state, action) => {
      state.isAuthenticated = action.payload;
    })
    .addCase(setIsAdmin, (state, action) => {
      state.isAdmin = action.payload;
    });
});

export default userDetailsReducer;
