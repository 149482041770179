import React, { useRef, useState } from "react";
import { getAudio } from "@services";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Message } from "../types";

interface AudioPlayerProps {
  messageDuration?: string;
  message: Message;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  messageDuration,
  message,
}) => {
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );
  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const togglePlay = async () => {
    const audioPlayer = audioPlayerRef.current;

    if (isPlaying) {
      // Pause playback if already playing
      if (audioPlayer) {
        audioPlayer.pause();
      }
      setIsPlaying(false);
      return;
    }

    // Fetch audio only if it hasn't been fetched yet
    if (!audioUrl) {
      setLoading(true);
      try {
        const response = await getAudio(message.id);
        const blob = new Blob([response.data], { type: "audio/wav" });
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);

        audioPlayerRef.current = new Audio(url);
        audioPlayerRef.current.addEventListener("ended", () =>
          setIsPlaying(false),
        );
      } catch (error) {
        console.error("Error fetching audio:", error);
        return;
      } finally {
        setLoading(false);
      }
    }

    if (audioPlayerRef.current) {
      audioPlayerRef.current.play();
      setIsPlaying(true);
    }
  };

  const formattedDuration = messageDuration?.includes("00:0")
    ? messageDuration.split(/00:0(.+)?/)[1]
    : messageDuration;

  return (
    <div className="recording">
      {loading ? (
        <i
          className="fa fa-circle-o-notch fa-spin"
          aria-hidden="true"
          data-testid="loading-icon"
        />
      ) : (
        <span
          className={`audio-player-icon ${isPlaying ? "stop" : "play"}`}
          onClick={togglePlay}
          title={
            isPlaying
              ? translations.MYTD_stopMsgLbl
              : translations.MYTD_playMsgLbl
          }
        ></span>
      )}
      {formattedDuration && (
        <span className="duration hidden-xs hidden-sm">
          <small>{formattedDuration}</small>
        </span>
      )}
      {audioUrl && <audio ref={audioPlayerRef} src={audioUrl} />}
    </div>
  );
};

export default AudioPlayer;
