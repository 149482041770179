import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RawHTML from "@common/RawHTML/Index";
import useScrollToHash from "@common/UseScrollToHash/Index";
import Breadcrumb from "@common/Breadcrumb";
import { ImageGallery, TrialText, SystemDownloadButton } from "./Common";
import SubProductsList from "./SubProductsList";
import ProductNotesList from "./ProductNotesList";
import OneTimeSinglePurchase from "./OneTimeSinglePurchase";
import Professionals from "./Professionals";
import TryItOuts from "./TryItOuts";
import OneTimePurchases from "./OneTimePurchases";
import FreePathways from "./FreePathways";
import Upgrades from "./Upgrade";
import OtherApp from "./OtherApplications";
import toast from "react-hot-toast";
import {
  getStoreInnerPageData,
  getSnapTrialsData,
  getSnapCoreFirstPerpetual,
} from "@services";

const StoreInner = ({ pageKey }) => {
  const { languageCode } = useSelector((state) => state.languageData);
  const { translations } = useSelector((state) => state.translations);
  const { isAuthenticated } = useSelector((state) => state.user);
  const [innerPageData, setInnerPageData] = useState();
  const [selectedPathwaysLanguageUrl, setSelectedPathwaysLanguageUrl] =
    useState(null);
  const [selectedPathwaysLanguage, setSelectedPathwaysLanguage] = useState(
    translations.Language,
  );
  const [snapTrialsData, setSnapTrialsData] = useState([]);
  const [snapCoreFirstPerpetual, setSnapCoreFirstPerpetual] = useState();

  useScrollToHash(innerPageData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getStoreInnerPageData(pageKey, languageCode);
        setInnerPageData(data);
      } catch (error) {
        toast.error(
          translations.An_error_occurred_while_processing_your_request,
        );
      }
    };

    fetchData();
  }, [languageCode, pageKey]);
  const handleUrlSelection = (url, language) => {
    setSelectedPathwaysLanguageUrl(url);
    setSelectedPathwaysLanguage(language);
  };

  const handleButtonClick = () => {
    if (selectedPathwaysLanguageUrl) {
      window.open(selectedPathwaysLanguageUrl, "_blank");
    }
  };

  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchSnapTrialsData = async () => {
      try {
        const data = await getSnapTrialsData(languageCode);
        setSnapTrialsData(data);
      } catch (error) {
        toast.error(
          translations.An_error_occurred_while_processing_your_request,
        );
      }
    };

    fetchSnapTrialsData();
  }, [languageCode, isAuthenticated]);

  useEffect(() => {
    const fetchSnapCoreFirstPerpetual = async () => {
      try {
        const data = await getSnapCoreFirstPerpetual(languageCode);
        setSnapCoreFirstPerpetual(data);
      } catch (error) {
        toast.error(
          translations.An_error_occurred_while_processing_your_request,
        );
      }
    };

    fetchSnapCoreFirstPerpetual();
  }, [languageCode]);

  return (
    <>
      {innerPageData && translations && (
        <>
          <div className="container-full">
            <div className="container myStuff-header">
              <h1>{innerPageData.title}</h1>
              <Breadcrumb
                items={[
                  {
                    href: "/store",
                    label: translations.Store,
                  },
                  {
                    label:
                      innerPageData.subTitle === "ALL"
                        ? innerPageData.subTitle
                        : innerPageData.title,
                  },
                ]}
              />
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-md-push-8">
                <h2>{innerPageData.headline}</h2>
                <RawHTML htmlContent={innerPageData.description} />

                {innerPageData.productNotes && (
                  <ProductNotesList productNotes={innerPageData.productNotes} />
                )}

                <div style={{ display: "flex" }}>
                  {pageKey === "SnapCoreFirst" && (
                    <>
                      <SystemDownloadButton
                        url={
                          innerPageData?.applications?.applications[0]?.apps[0]
                            ?.links
                        }
                        translations={translations}
                        system="AppStore"
                      />
                      <div style={{ marginLeft: "20px" }}>
                        <TrialText
                          trialPeriodText={translations.Start_Day_Trial}
                          trialPeriod={60}
                          trialPeriodEndedText={translations.Day_Trial_Ended}
                          trialDaysRemainingText={translations.Days_remaining}
                          trialLink="start-trial?product=Speech"
                          trialToken={snapTrialsData.find(
                            (token) => token?.type === "Speech",
                          )}
                        />
                      </div>
                    </>
                  )}

                  {pageKey === "SnapCoreFirstPerpetual" && (
                    <div className="pricing">
                      <h3>
                        {
                          innerPageData?.oneTimeSinglePurchase?.applications[0]
                            ?.apps[0]?.price
                        }
                      </h3>
                      <h4>
                        {
                          innerPageData?.oneTimeSinglePurchase?.applications[0]
                            ?.type
                        }
                      </h4>
                      <a href="#shopNow" className="btn btn-lg btn-warning">
                        {translations.Shop_Now}
                      </a>
                    </div>
                  )}
                  {pageKey === "SpeechCasePodd" && (
                    <div className="pricing">
                      <h3>
                        {
                          innerPageData?.oneTimeSinglePurchase?.applications[0]
                            ?.apps[0]?.price
                        }
                      </h3>
                      <h4>
                        {
                          innerPageData?.oneTimeSinglePurchase?.applications[0]
                            ?.type
                        }
                      </h4>
                    </div>
                  )}
                  {pageKey === "SpeechCaseGateway" && (
                    <div className="pricing">
                      <h3>
                        {
                          innerPageData?.oneTimeSinglePurchase?.applications[0]
                            ?.apps[0]?.price
                        }
                      </h3>
                      <h4>
                        {
                          innerPageData?.oneTimeSinglePurchase?.applications[0]
                            ?.type
                        }
                      </h4>
                    </div>
                  )}
                  {pageKey !== "SnapCoreFirst" &&
                    pageKey !== "SpeechCasePodd" &&
                    pageKey !== "SnapCoreFirstPerpetual" &&
                    pageKey !== "SpeechCaseGateway" && (
                      <Link
                        to={
                          innerPageData.title === "Pathways"
                            ? "#browse"
                            : "#shopNow"
                        }
                        className="btn btn-lg btn-warning"
                        rel="noreferrer"
                      >
                        {innerPageData.title === "Pathways"
                          ? translations.Browse
                          : translations.Shop_Now}
                      </Link>
                    )}
                </div>
              </div>
              <div className="col-md-7 col-md-pull-4">
                <ImageGallery imagePaths={innerPageData.images} />
              </div>
            </div>
          </div>
          {innerPageData.tryItOuts && (
            <TryItOuts
              tryItOuts={innerPageData.tryItOuts}
              translations={translations}
              product={pageKey}
            />
          )}
          {innerPageData.oneTimeSinglePurchase && (
            <OneTimeSinglePurchase
              oneTimeSinglePurchase={innerPageData.oneTimeSinglePurchase}
              translations={translations}
              product={pageKey}
            />
          )}
          {innerPageData.professionals && (
            <Professionals
              professionals={innerPageData.professionals}
              translations={translations}
            />
          )}
          {innerPageData.oneTimePurchases && (
            <OneTimePurchases
              oneTimePurchases={innerPageData.oneTimePurchases}
              translations={translations}
              pageKey={pageKey}
              oneTimeSinglePurchase={innerPageData.oneTimeSinglePurchase}
            />
          )}
          {innerPageData.freePathways && (
            <FreePathways
              freePathways={innerPageData.freePathways}
              translations={translations}
              selectedPathwaysLanguageUrl={selectedPathwaysLanguageUrl}
              selectedPathwaysLanguage={selectedPathwaysLanguage}
              handleUrlSelection={handleUrlSelection}
              handleButtonClick={handleButtonClick}
              pageKey={pageKey}
              snapCFPFreePathaways={snapCoreFirstPerpetual?.freePathways}
            />
          )}
          {innerPageData.applications && (
            <div id="shopNow" className="container-full">
              {innerPageData.subProducts && (
                <SubProductsList
                  subProducts={innerPageData.subProducts.subProducts}
                />
              )}
              <Upgrades
                upgrades={innerPageData.upgrades}
                translations={translations}
                snapTrialsData={snapTrialsData}
              />
              <OtherApp otherApplications={innerPageData.otherApplications} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default StoreInner;
