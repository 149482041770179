import { createAction } from "@reduxjs/toolkit";
import { getUserProfile, getUserRoles } from "@services/user-service";
import { AppDispatch } from "store";

interface UserProfile {
  id: string;
  name: string;
  email: string;
}

interface UserRolesStatus {
  isAdmin: boolean;
}

export const setUserDetails = createAction<UserProfile>("users/setUserDetail");

export const setUserDataFailure = createAction<Error>(
  "users/setUserDataFailure",
);
export const setAuthentication = createAction<boolean>(
  "auth/setAuthentication",
);
export const setIsAdmin = createAction<boolean>("auth/setIsAdmin");

export const fetchUserDetail =
  (langCode: string) => async (dispatch: AppDispatch) => {
    try {
      const data: UserProfile = await getUserProfile(langCode);

      dispatch(setUserDetails(data));
    } catch (error) {
      dispatch(
        setUserDataFailure(
          error instanceof Error ? error : new Error("Unknown error"),
        ),
      );
    }
  };

export const fetchIsAdmin = () => async (dispatch: AppDispatch) => {
  try {
    const roles: UserRolesStatus = await getUserRoles();

    dispatch(setIsAdmin(roles.isAdmin));
  } catch (error) {
    dispatch(setIsAdmin(false));
  }
};
