import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { getContactPageData } from "@services";
import PopupSurvey from "../PopupSurvey/PopupSurvey";
import { RootState } from "store";
import ModalTermsAndConditions from "./ModalTermsAndConditions";
import toast from "react-hot-toast";

type CompanyData = {
  title: string;
  aboutUs: { url: string; displayText: string };
  home: { url: string; displayText: string };
};

type PrivacyData = {
  title: string;
  privacyPolicy: { url: string; displayText: string };
  privacyPolicyChina: { url: string; displayText: string };
  termsAndConditions: { url: string; displayText: string };
};

type ContactData = {
  title: string;
  addresses: Address[];
};

type Address = {
  name: string;
  addressFirstLine: string;
  addressSecondLine: string;
  cityAndCode: string;
  country: string;
  phone: string;
  fax: string;
};

type Redirect = {
  type: string;
  displayText: string;
  redirects: SubRedirect[];
};

type SubRedirect = {
  type: string;
  displayText: string;
  url: string;
};

const Footer = () => {
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );
  const { redirections } = useSelector((state: RootState) => state.common);
  const { languageCode } = useSelector(
    (state: RootState) => state.languageData,
  );

  const [contactData, setContactData] = useState<ContactData>();
  const [companyData, setCompanyData] = useState<CompanyData>();
  const [privacyData, setPrivacyData] = useState<PrivacyData>();
  const [showModal, setShowModal] = useState(false);

  const toggleModal = useCallback(() => setShowModal((prev) => !prev), []);

  useEffect(() => {
    const extractData = (type: string): CompanyData | PrivacyData | null => {
      if (!redirections) return null;

      const redirectionData = redirections.find(
        (item: Redirect) => item.type === type,
      );

      if (!redirectionData) return null;

      const data = {
        title: redirectionData.displayText,
        ...(type === "Company" && { home: {}, aboutUs: {} }),
        ...(type === "Privacy" && {
          privacyPolicy: {},
          privacyPolicyChina: {},
          termsAndConditions: {},
        }),
      };

      redirectionData.redirects.forEach((redirect) => {
        if (type === "Company") {
          if (redirect.type === "HomePage") {
            data.home = {
              displayText: redirect.displayText,
              url: redirect.url,
            };
          } else if (redirect.type === "AboutUs") {
            data.aboutUs = {
              displayText: redirect.displayText,
              url: redirect.url,
            };
          }
        } else if (type === "Privacy") {
          if (redirect.type === "PrivacyPolicy") {
            data.privacyPolicy = {
              displayText: redirect.displayText,
              url: redirect.url,
            };
          } else if (redirect.type === "PrivcayPolicyChina") {
            data.privacyPolicyChina = {
              displayText: redirect.displayText,
              url: redirect.url,
            };
          } else if (redirect.type === "TermsAndConditions") {
            data.termsAndConditions = {
              displayText: redirect.displayText,
              url: redirect.url,
            };
          }
        }
      });

      return data as CompanyData | PrivacyData;
    };

    if (redirections && !companyData && !privacyData) {
      const companyExtracted = extractData("Company") as CompanyData | null;
      const privacyExtracted = extractData("Privacy") as PrivacyData | null;

      if (companyExtracted) setCompanyData(companyExtracted);
      if (privacyExtracted) setPrivacyData(privacyExtracted);
    }
  }, [redirections]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!contactData) {
          const contact = await getContactPageData(languageCode);
          setContactData(contact);
        }
      } catch (error) {
        toast.error(
          translations.An_error_occurred_while_processing_your_request,
        );
      }
    };

    fetchData();
  }, [languageCode, contactData]);

  return (
    <>
      {translations && (
        <footer className="no-print main-footer">
          <div className="container">
            <div className="row">
              {contactData &&
                contactData.addresses.map((address, index) => (
                  <div
                    className="hidden-xs col-sm-6 col-lg-3"
                    key={`${address.name}_${index}_`}
                  >
                    <h3>{index === 0 ? contactData.title : <>&nbsp;</>}</h3>

                    <address>
                      {address.name}
                      <br />
                      {address.addressFirstLine}
                      <br />
                      {address.addressSecondLine
                        ? address.addressSecondLine
                        : address.cityAndCode}
                      <br />
                      {address.country ? address.country : address.cityAndCode}
                      <br />
                      <br />
                      {translations.Phone}: {address.phone}
                      <br />
                      {translations.Fax}: {address.fax}
                    </address>
                  </div>
                ))}

              {companyData && (
                <div className="col-sm-6 col-lg-3 footer-col-xs-spacing">
                  <h3>{companyData.title}</h3>
                  <a
                    href={companyData.home.url}
                    className=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    {companyData.home.displayText}
                  </a>
                  <span className="external-link"></span>
                  <br />
                  <a
                    href={companyData.aboutUs.url}
                    className=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    {companyData.aboutUs.displayText}
                  </a>
                  <span className="external-link"></span>
                </div>
              )}
              <div className="col-sm-6 col-lg-3 footer-col-xs-spacing clearfix">
                <h3>{translations.Stay_Connected}</h3>
                <ul className="social-wrapper">
                  <li>
                    <a
                      href="https://www.facebook.com/tobiidynavox"
                      className="facebook social-facebook"
                      title="Facebook"
                      target="_blank"
                      rel="noreferrer"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/tobiidynavox"
                      className="twitter social-twitter"
                      title="Twitter"
                      target="_blank"
                      rel="noreferrer"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCSFhjTdkb4rt4GiIPmDypQA"
                      className="youtube social-youtube"
                      title="YouTube"
                      target="_blank"
                      rel="noreferrer"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.pinterest.com/dynavox/"
                      className="pinterest social-pinterest"
                      title="Pinterest"
                      target="_blank"
                      rel="noreferrer"
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
            {privacyData && (
              <div className="row copyright">
                <div className="col-sm-6 footer-col-xs-spacing">
                  <a
                    href={privacyData.privacyPolicy.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {privacyData.privacyPolicy.displayText}
                  </a>
                  <a
                    href={privacyData.privacyPolicyChina.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {privacyData.privacyPolicyChina.displayText}
                  </a>
                  <a onClick={toggleModal} style={{ cursor: "pointer" }}>
                    {privacyData.termsAndConditions.displayText}
                  </a>
                </div>
                <div className="col-sm-6">
                  &copy; {new Date().getFullYear()} - Tobii Dynavox
                </div>
              </div>
            )}
          </div>
          <PopupSurvey surveyUrl="https://www.surveymonkey.com/r/NPS_MYTD" />
        </footer>
      )}
      {privacyData?.termsAndConditions.url && (
        <ModalTermsAndConditions
          show={showModal}
          onClose={toggleModal}
          termsContent={privacyData.termsAndConditions.url}
        />
      )}
    </>
  );
};

export default Footer;
