import React from "react";
import Translation from "@common/Translation";

interface TableHeaderProps {
  onSelectAll: (selectAll: boolean) => void;
  allSelected: boolean;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  onSelectAll,
  allSelected,
}) => {
  return (
    <thead>
      <tr>
        {/* Title Column */}
        <th className="col-xs-3 sortable">
          <input
            checked={allSelected}
            onChange={(e) => onSelectAll(e.target.checked)}
            type="checkbox"
          />
          <Translation translate="MYTD_titleTxt" />{" "}
        </th>

        {/* Text Column */}
        <th className="col-xs-3 sortable">
          <Translation translate="MYTD_MBListText" />{" "}
        </th>

        {/* Confidence Column */}
        <th className="col-xs-1 text-center">
          <span className="hidden-xs hidden-sm hidden-md">
            <Translation translate="MYTD_MBListConfidence" />
          </span>
          <span className="hidden-lg">
            <Translation translate="MYTD_MBListConf" />
          </span>
        </th>

        {/* Recording Column */}
        <th className="col-xs-1">
          <span className="hidden-xs hidden-sm hidden-md">
            <Translation translate="MYTD_MBListRecording" />
          </span>
          <span className="hidden-lg">
            <Translation translate="MYTD_MBListRec" />
          </span>
        </th>

        {/* Tags Column */}
        <th className="col-xs-3">
          <Translation translate="MYTD_MBListTags" />
        </th>

        {/* Empty Column for Actions */}
        <th className="col-xs-1"></th>
      </tr>
    </thead>
  );
};

export default TableHeader;
