import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { cultureService } from "@services";
import { setLanguage } from "../../../../actions";
import { RootState } from "store";

type LanguageInfo = {
  LanguageCode: string;
  LanguageName: string;
};

interface LanguageData {
  LanguageCode: string;
  LanguageName: string;
  LanguageInfos: LanguageInfo[];
}

const useLanguageData = () => {
  const dispatch = useDispatch();
  const [languageData, setLanguageData] = useState<LanguageData | null>(null);
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );

  useEffect(() => {
    try {
      const data = cultureService.getCultureOptions() as LanguageData | null;
      setLanguageData(data);
      if (data) {
        dispatch(
          setLanguage({
            languageCode: data.LanguageCode,
            languageName: data.LanguageName,
          }),
        );
      }
    } catch (error) {
      toast.error(translations.An_error_occurred_while_processing_your_request);
    }
  }, [dispatch]);

  const handleLanguageChange = useCallback(
    (languageCode: string, languageName: string) => {
      dispatch(setLanguage({ languageCode, languageName }));
      cultureService.setCulture(languageCode);
    },
    [dispatch],
  );

  return { languageData, handleLanguageChange };
};

export default useLanguageData;
