import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Translation from "@common/Translation";
import { Language } from "./types";
import { FileWithMetadata } from "types";

interface UploadQueueModalProps {
  showModal: boolean;
  fileQueue: FileWithMetadata[];
  availableLangs: Language[];
  onCancel: () => void;
  onStart: (languageModelId: string) => void;
}

const UploadQueueModal: React.FC<UploadQueueModalProps> = ({
  showModal,
  fileQueue,
  availableLangs,
  onCancel,
  onStart,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    "DBCE32BB-60C0-42A3-ACEC-7D6F083AB1BB", // Default to English
  );

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedLanguage(event.target.value);
  };

  const totalFileSize = fileQueue.reduce(
    (total, file) => total + file.file.size,
    0,
  );

  return (
    <Modal
      show={showModal}
      backdrop="static"
      onHide={onCancel}
      className={showModal ? "in" : ""}
    >
      <Modal.Header>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onCancel}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <Modal.Title>
          <h2>
            <Translation translate="MYTD_uploadLbl" />
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="upload-queue">
          <div className="file-list">
            {fileQueue.map((file, index) => (
              <div key={index}>{file.file.name}</div>
            ))}
          </div>
        </div>
        <div className="alert alert-warning">
          <Translation translate="MyTD_msgBank16BitWarning" />
        </div>
        <div className="transcription-settings">
          <label htmlFor="transcription-language">
            <Translation translate="MYTD_transcribeToLbl" />
          </label>
          <select
            id="transcription-language"
            className="form-control"
            value={selectedLanguage}
            onChange={handleLanguageChange}
          >
            <option value="noTranscribe">
              <Translation translate="MYTD_noTranscribeLbl" />
            </option>
            {availableLangs.map((lang) => (
              <option key={lang.id} value={lang.id}>
                {lang.name}
              </option>
            ))}
          </select>
        </div>
        <br></br>
        <p>
          <Translation translate="MYTD_filesToUploadTxt" /> {fileQueue.length},{" "}
          <Translation translate="MYTD_totalSizeLbl" />{" "}
          {(totalFileSize / 1024 / 1024).toFixed(2)} MB
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-default" onClick={onCancel}>
          <Translation translate="MYTD_cancelBtnTxt" />
        </button>
        <button
          className="btn btn-primary"
          onClick={() => onStart(selectedLanguage)}
          disabled={!selectedLanguage}
        >
          <Translation translate="MYTD_startLbl" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadQueueModal;
