import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { BulkAction, Message } from "../types";
import MessageDeleteModal from "../DeleteModal";

interface MessageActionsProps {
  message: Message;
  bulkAction: BulkAction;
  resetBulkAction: () => void;
  onMessageDelete: (message: Message) => void;
}

const MessageActions: React.FC<MessageActionsProps> = ({
  message,
  bulkAction,
  onMessageDelete,
  resetBulkAction,
}) => {
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const handleOpenModal = () => {
    resetBulkAction();
    setShowDeleteModal(true);
  };

  return (
    <>
      <span
        className="message-icon delete-message"
        onClick={handleOpenModal}
        style={{ cursor: "pointer" }}
        title={translations.MYTD_deleteMsgLbl}
      ></span>
      {showDeleteModal && (
        <MessageDeleteModal
          showModal={showDeleteModal}
          onConfirmationClose={() => setShowDeleteModal(false)}
          message={message}
          onMessageDelete={onMessageDelete}
          bulkAction={bulkAction}
          resetBulkAction={resetBulkAction}
        />
      )}
    </>
  );
};

export default MessageActions;
