import React, { useState } from "react";
import { useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { SingleValue, ActionMeta } from "react-select";
import { RootState } from "store";
import { Message } from "../types";

interface TagsProps {
  message: Message;
  tags: { label: string; value: string }[];
  onTagAdd: (message: Message, tagName: string) => void;
  onTagDelete: (message: Message, tagName: string) => void;
}

const Tags: React.FC<TagsProps> = ({
  message,
  tags,
  onTagAdd,
  onTagDelete,
}) => {
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );
  const [showTagForm, setShowTagForm] = useState<boolean>(false);

  const toggleTagForm = () => setShowTagForm((prev) => !prev);

  const handleChange = (
    selected: SingleValue<{ label: string; value: string }>,
    actionMeta: ActionMeta<{ label: string; value: string }>,
  ) => {
    if (selected && actionMeta.action === "select-option") {
      // Existing tag selected
      onTagAdd(message, selected.value);
    }
    setShowTagForm(false);
  };

  const handleCreateOption = (inputValue: string) => {
    // Add a new tag
    if (inputValue.trim()) {
      onTagAdd(message, inputValue.trim());
      setShowTagForm(false);
    }
  };

  return (
    <div className="tag-list">
      <div className="form-inline">
        <button
          className="btn message-icon add-tag"
          onClick={toggleTagForm}
          title={translations.MYTD_addTagLbl}
        ></button>
        {showTagForm && (
          <div className="tag-select-dropdown">
            <CreatableSelect
              options={tags}
              onChange={handleChange}
              onCreateOption={handleCreateOption}
              placeholder={translations.MYTD_addTagLbl}
              isClearable
              classNamePrefix="custom-select"
              isSearchable
              formatCreateLabel={(inputValue) =>
                `${translations.MYTD_addLbl} "${inputValue}"`
              }
              menuIsOpen
            />
          </div>
        )}
      </div>
      <div className="form-group tags">
        {message.tags.map((tag, index) => (
          <span key={`tag_${index}`} className="tag">
            {tag.name}
            <span
              className="message-icon remove-tag"
              onClick={() => onTagDelete(message, tag.name)}
              title={translations.MYTD_deleteTagLbl}
            ></span>
          </span>
        ))}
      </div>
    </div>
  );
};

export default Tags;
