import React, { useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import { redirectToLogin } from "@services";
import { AuthContext } from "@common/AuthContext";

interface ProtectedRouteProps {
  children?: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error("ProtectedRoute must be used within an AuthProvider");
  }

  const { isAuthenticated } = authContext;

  useEffect(() => {
    if (!isAuthenticated) {
      redirectToLogin();
    }
  }, [isAuthenticated]);

  return <>{children || <Outlet />}</>;
};

export default ProtectedRoute;
