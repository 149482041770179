import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMessages, getMessageBankLanguages } from "@services";
import Translation from "@common/Translation";
import Table from "./Table";
import MessageListControls from "./Filters/MessageListControls";
import Pagination from "./Filters/Pagination";
import BundleGeneratorMessage from "./BundleGeneratorMessage";
import HeaderButtons from "./HeaderButtons";
import { Language, Message, Filters, PageBundleStatus } from "./types";
import { State } from "types";

const MessageBankMessages: React.FC = () => {
  const { languageCode } = useSelector((state: State) => state.languageData);

  const [messages, setMessages] = useState<Message[]>([]);
  const [availableLangs, setAvailableLangs] = useState<Language[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters>({
    sort: "-created",
    search: null,
    searchField: "all",
    pagination: { page: 1, pageSize: 25 },
    filter: { verified: null },
  });
  const [totalPageCount, setTotalPageCount] = useState<number>(0);
  const [totalMessagesCount, setTotalMessagesCount] = useState<number>(0);
  const [showBundleBox, setShowBundleBox] = useState<boolean>(false);
  const [bundleName, setBundleName] = useState<string>("");
  const [bundleStatus, setBundleStatus] = useState<PageBundleStatus>({
    inProgress: false,
    completed: false,
    failed: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await getMessages(languageCode, filters);
        const fetchedMessages = data._Embedded.recordedMessages.map(
          (msg: Message) => ({
            ...msg,
            selected: false,
          }),
        );
        setMessages(fetchedMessages);
        setTotalMessagesCount(data.totalRecordedMessagesCount);
        setTotalPageCount(data.totalPageCount);
      } catch (error) {
        console.error("Error fetching messages:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [languageCode, filters]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getMessageBankLanguages(languageCode);
      setAvailableLangs(data._Embedded.languageModels);
    };
    fetchData();
  }, [languageCode]);

  const handleFilterChange = (updatedPartialFilters: Partial<Filters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...updatedPartialFilters,
    }));
  };

  const handleMessageSelect = (message: Message) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === message.id ? { ...msg, selected: !msg.selected } : msg,
      ),
    );
  };

  const handleSelectAll = (selectAll: boolean) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) => ({ ...msg, selected: selectAll })),
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <HeaderButtons availableLangs={availableLangs} />

      <div className="container ms-bank">
        <div className="mmb-wrapper">
          <div className="fade-block fade-in margin-top-lg mb-app">
            <div className="container-fluid padding-bottom-reset">
              <div className="row">
                {/* Loading state */}
                {loading && (
                  <div className="alert alert-info">
                    <Translation translate="MYTD_MBMsgLoading" />
                  </div>
                )}
                {/* Bundle generator progress box */}
                {showBundleBox && (
                  <BundleGeneratorMessage
                    setShowBundleBox={setShowBundleBox}
                    bundleStatus={bundleStatus}
                    bundleName={bundleName}
                  />
                )}

                {messages.length > 0 && (
                  <>
                    {/* Messages controls and filters */}
                    <MessageListControls
                      handleFilterChange={handleFilterChange}
                      filters={filters}
                      totalPageCount={totalPageCount}
                      totalMessagesCount={totalMessagesCount}
                      messages={messages}
                      setShowBundleBox={setShowBundleBox}
                      bundleStatus={bundleStatus}
                      setBundleStatus={setBundleStatus}
                      setBundleName={setBundleName}
                    />
                    {/* Messages table */}
                    <Table
                      messages={messages}
                      setMessages={setMessages}
                      onMessageSelect={handleMessageSelect}
                      onSelectAll={handleSelectAll}
                      availableLangs={availableLangs}
                    />
                    <Pagination
                      totalPageCount={totalPageCount}
                      totalMessagesCount={totalMessagesCount}
                      itemsPerPage={filters.pagination.pageSize}
                      currentPage={filters.pagination.page}
                      messages={messages}
                      filters={filters}
                      handleFilterChange={handleFilterChange}
                    />
                  </>
                )}

                {/* No messages found */}
                {!loading && messages.length === 0 && (
                  <div className="alert alert-info">
                    <Translation translate="MYTD_noMsgLbl" />
                    {filters.search && (
                      <div style={{ marginTop: "10px" }}>
                        <button
                          className="btn btn-default"
                          onClick={() => handleFilterChange({ search: null })}
                        >
                          <Translation translate="Reset" />{" "}
                          <Translation translate="Filters" />
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageBankMessages;
