import React, { useState } from "react";
import Select, { SingleValue } from "react-select";
import { Filters, OptionType } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "store";

interface SearchProps {
  filters: Filters;
  handleFilterChange: (updatedFilters: Partial<Filters>) => void;
}

const Search: React.FC<SearchProps> = ({ filters, handleFilterChange }) => {
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );
  const [localSearch, setLocalSearch] = useState<string>(filters.search || "");
  const searchFieldOptions: OptionType[] = [
    { value: "all", label: translations.Search_All },
    { value: "title", label: translations.Title },
    { value: "text", label: translations.MYTD_MBListText },
    { value: "tags", label: translations.MYTD_MBListTags },
  ];

  const handleSearchFieldChange = (selectedOption: SingleValue<OptionType>) => {
    if (selectedOption) {
      handleFilterChange({ searchField: selectedOption.value });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLocalSearch(value);
    if (value.trim() === "") {
      handleFilterChange({ search: null });
    } else {
      handleFilterChange({ search: value });
    }
  };

  const clearSearch = () => {
    setLocalSearch("");
    handleFilterChange({ search: null });
  };

  return (
    <div className="col-xs-12 col-md-4" style={{ padding: "0" }}>
      <div className="searchHold margin-top-lg">
        <div>
          <label htmlFor="searchBox" className="mb-searchIcon">
            <i className="icon-btn-search"></i>
          </label>
          <input
            placeholder={translations.MYTD_searchPlaceholder}
            type="text"
            name="searchBox"
            id="searchBox"
            className="form-control search-box"
            value={localSearch ?? ""}
            onChange={handleInputChange}
          />
          {localSearch && (
            <span className="mb-iconStyleClear" onClick={clearSearch}>
              <i className="icon-btn-close-modal"></i>
            </span>
          )}
        </div>
        <div>
          <Select
            options={searchFieldOptions}
            placeholder={translations.select_category}
            defaultValue={searchFieldOptions.find(
              (option) => option.value === filters.searchField,
            )}
            onChange={handleSearchFieldChange}
            classNamePrefix="react-select"
            isSearchable={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Search;
