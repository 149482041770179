import React, { useState, useEffect } from "react";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { getEulaData } from "@services";
import { Eula } from "types";
import { ResourcesList } from "types";
import { EulaLinks } from "types";
import PdfComponent from "./PdfComponent";
import toast from "react-hot-toast";

const EulaIndex = () => {
  const { languageCode } = useSelector(
    (state: RootState) => state.languageData,
  );
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );
  const [eulaData, setEulaData] = useState<Eula | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getEulaData(languageCode);
        setEulaData(data);
      } catch (error) {
        toast.error(
          translations.An_error_occurred_while_processing_your_request,
        );
      }
    };
    fetchData();
  }, [languageCode]);

  return (
    <>
      {eulaData && (
        <>
          <div className="container">
            <br />
            <img src="/assets/images/logo-color.svg" alt="" />
            <h1 style={{ marginTop: "20px" }}>{eulaData.title}</h1>
          </div>
          <div id="docsPage">
            <div className="resources" style={{ marginLeft: "235px" }}>
              {eulaData.resourcesList.map(
                (resourceItem: ResourcesList, resourceIndex: number) => {
                  const categories = Array.from(
                    new Set(
                      resourceItem.links.map((link) => link.category || ""),
                    ),
                  );

                  return (
                    <div key={resourceIndex}>
                      <h2>{resourceItem.title}</h2>
                      <div className="panel-group">
                        {categories.map((category, categoryIndex) => {
                          const categoryLinks = resourceItem.links.filter(
                            (link) =>
                              link.category === category ||
                              (!link.category && category === "Uncategorized"),
                          );

                          return (
                            <React.Fragment key={categoryIndex}>
                              {category && (
                                <div className="panel-heading">
                                  <h4>{category}</h4>
                                </div>
                              )}
                              <React.Fragment>
                                {categoryLinks.map((link, linkIndex) => (
                                  <PdfComponent
                                    key={linkIndex}
                                    resourceType={link.resourceType}
                                    mainUrl={link.mainUrl}
                                    mainUrlText={link.mainUrlText}
                                    description={link.description}
                                    availability={link.availability}
                                  />
                                ))}
                              </React.Fragment>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                  );
                },
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EulaIndex;
