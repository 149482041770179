import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Translation from "@common/Translation";
import MoreStuffProVerifyBtn from "./MoreStuffProfVerifyBtn";
import { emailRegex } from "../../../../helpers/utilities";
import FormErrors from "./FormErrors";
import { getProfessionalAffiliations } from "@services";
import { postVerifyProfessionals } from "@services";
import toast from "react-hot-toast";

const MoreStuffProForm = ({ userProfile, onClickChange }) => {
  const { translations } = useSelector((state) => state.translations);
  const { languageCode } = useSelector((state) => state.languageData);
  const [professionalAffiliations, setProfessionalAffiliations] = useState([]);

  useEffect(() => {
    const fetchProfessionalAffiliations = async () => {
      try {
        const data = await getProfessionalAffiliations(languageCode);
        setProfessionalAffiliations(data);
      } catch (error) {
        toast.error(
          translations.An_error_occurred_while_processing_your_request,
        );
      }
    };
    fetchProfessionalAffiliations();
  }, [languageCode, translations]);

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    streetAddressLine1: "",
    streetAddressLine2: "",
    city: "",
    zipCode: "",
    organization: "",
    memberType: "",
    membershipNumber: "",
    description: "",
    proAgreement: false,
  });

  const [formErrors, setFormErrors] = useState([]);

  useEffect(() => {
    setFormState({
      firstName: userProfile?.firstName || "",
      lastName: userProfile?.lastName || "",
      email: userProfile?.email || "",
      country: userProfile?.address?.country || "",
      streetAddressLine1: userProfile?.address?.streetAddressLine1 || "",
      streetAddressLine2: userProfile?.address?.streetAddressLine2 || "",
      city: userProfile?.address?.city || "",
      zipCode: userProfile?.address?.zipCode || "",
      organization: "",
      memberType: "",
      membershipNumber: "",
      description: "",
      proAgreement: false,
    });
  }, [userProfile]);

  const validateField = (fieldName, value) => {
    let errorKey = null;

    switch (fieldName) {
      case "firstName":
      case "lastName":
        if (value.length > 200) {
          errorKey = `MYTD_${fieldName}200Msg`;
        }
        break;
      case "email":
        if (!emailRegex.test(value)) {
          errorKey = "MYTD_validEmailMsg";
        }
        break;
      case "streetAddressLine1":
        if (!value) {
          errorKey = "MYTD_streetAddMissingMsg";
        }
        break;
      case "city":
        if (!value) {
          errorKey = "MYTD_cityMissingMsg";
        }
        break;
      case "zipCode":
        if (!value) {
          errorKey = "MYTD_zipRequiredMsg";
        }
        break;
      case "country":
        if (!value || value === "0") {
          errorKey = "MYTD_selectCountryMsg";
        }
        break;
      case "organization":
        if (!value) {
          errorKey = "MYTD_CompanyReqMsg";
        }
        break;
      case "membershipNumber":
        if (!value) {
          errorKey = "MYTD_memNumReqMsg";
        }
        break;
      case "memberType":
        if (!value || value === "0") {
          errorKey = "MYTD_proAffReqMsg";
        }
        break;
      case "proAgreement":
        if (!value) {
          errorKey = "MYTD_mustAgreeTermsMsg";
        }
        break;
      default:
        break;
    }

    return errorKey;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;

    setFormState((prev) => ({ ...prev, [name]: fieldValue }));

    const errorKey = validateField(name, fieldValue);

    setFormErrors((prevErrors) => {
      const updatedErrors = prevErrors.filter((error) => error.field !== name);

      if (errorKey) {
        updatedErrors.push({ field: name, key: errorKey });
      }

      return updatedErrors;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validateAllFields = (formData) => {
      return Object.entries(formData).reduce((errors, [fieldName, value]) => {
        const errorKey = validateField(fieldName, value);
        if (errorKey) {
          errors.push({ field: fieldName, key: errorKey });
        }
        return errors;
      }, []);
    };

    const validationErrors = validateAllFields(formState);
    if (validationErrors.length > 0) {
      setFormErrors(validationErrors);
      return;
    }

    const apiPayload = {
      addressLine1: formState.streetAddressLine1,
      addressLine2: formState.streetAddressLine2,
      addressCity: formState.city,
      addressZipCode: formState.zipCode,
      memberType: formState.memberType,
      company: formState.organization,
      membershipNumber: formState.membershipNumber,
      other: formState.description,
    };

    const requiredFields = [
      "addressLine1",
      "addressCity",
      "addressZipCode",
      "memberType",
      "company",
      "membershipNumber",
    ];
    requiredFields.forEach((field) => {
      if (!apiPayload[field]) {
        console.error(`${field} is missing in the payload.`);
      }
    });
    try {
      const response = await postVerifyProfessionals(apiPayload);
      toast.success(translations.action_completed, response);
    } catch (error) {
      toast.error(
        translations.An_error_occurred_while_processing_your_request,
        error,
      );
    }
  };

  return (
    <>
      <FormErrors errors={formErrors} />
      <div className="well fade-block fade-in" id="ASHADiv">
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="firstName" className="control-label">
              <Translation translate="MYTD_firstNameLbl" />
            </label>
            <input
              className="form-control"
              disabled
              id="firstName"
              name="firstName"
              type="text"
              value={formState.firstName}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="lastName" className="control-label">
              <Translation translate="MYTD_lastNameLbl" />
            </label>
            <input
              className="form-control"
              disabled
              id="lastName"
              name="lastName"
              type="text"
              value={formState.lastName}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="email" className="control-label">
              <Translation translate="MYTD_emailLbl" />
            </label>
            <input
              className="form-control"
              disabled
              id="email"
              name="email"
              type="text"
              value={formState.email}
              readOnly
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="country" className="control-label">
              <Translation translate="MYTD_countryLbl" />
            </label>
            <input
              className="form-control"
              disabled
              id="country"
              name="country"
              type="text"
              value={formState.country}
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-md-4">
            <a className="btn btn-default" onClick={onClickChange}>
              <Translation translate="MYTD_changeLbl" />
            </a>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-6 col-md-4 form-group">
            <label className="control-label" htmlFor="streetAddressLine1">
              <Translation translate="MYTD_add1Lbl" />
            </label>
            <input
              className="form-control"
              id="streetAddressLine1"
              name="streetAddressLine1"
              type="text"
              value={formState.streetAddressLine1}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-sm-6 col-md-4 form-group">
            <label className="control-label" htmlFor="streetAddressLine2">
              <Translation translate="MYTD_add2Lbl" />
            </label>
            <input
              className="form-control"
              id="streetAddressLine2"
              name="streetAddressLine2"
              type="text"
              value={formState.streetAddressLine2}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-md-4 form-group">
            <label htmlFor="city" className="control-label">
              <Translation translate="MYTD_cityLbl" />
            </label>
            <input
              className="form-control"
              id="city"
              name="city"
              type="text"
              value={formState.city}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-sm-6 col-md-4 form-group">
            <label htmlFor="zipCode" className="control-label">
              <Translation translate="MYTD_postalCodeLbl" />
            </label>
            <input
              className="form-control"
              id="zipCode"
              name="zipCode"
              type="text"
              value={formState.zipCode}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="organization" className="control-label">
              <Translation translate="MYTD_companyLbl" />
            </label>
            <input
              className="form-control"
              id="organization"
              name="organization"
              type="text"
              value={formState.organization}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="memberType" className="control-label">
              <Translation translate="MYTD_proAfflilLbl" />
            </label>
            <select
              className="form-control"
              id="memberType"
              name="memberType"
              value={formState.memberType}
              onChange={handleInputChange}
            >
              <option value="0">{translations.MYTD_proAssocLbl}</option>
              {professionalAffiliations.map((optionItem) => (
                <option value={optionItem.code} key={optionItem.code}>
                  {optionItem.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="membershipNumber" className="control-label">
              <Translation translate="MYTD_proIDLbl" />
            </label>
            <input
              className="form-control"
              id="membershipNumber"
              name="membershipNumber"
              type="text"
              value={formState.membershipNumber}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="description" className="control-label">
              <Translation translate="MYTD_otherAffilLbl" />
            </label>
            <textarea
              className="form-control"
              id="description"
              name="description"
              rows="3"
              value={formState.description}
              onChange={handleInputChange}
            ></textarea>
          </div>
        </div>
        <label className="checkbox">
          <i>
            <Translation translate="MYTD_verifyProDesc2" />
          </i>
          <br />
          <br />
          <input
            id="proAgreement"
            name="proAgreement"
            type="checkbox"
            checked={formState.proAgreement}
            onChange={handleInputChange}
          />
          <span>
            <Translation translate="MYTD_verifyProAgreeTxt" />
          </span>
        </label>
        <MoreStuffProVerifyBtn onClick={handleSubmit} />
      </div>
    </>
  );
};

export default MoreStuffProForm;
