import React, { useState } from "react";
import Select, { SingleValue } from "react-select";
import { useSelector } from "react-redux";
import { RootState } from "store";
import Translation from "@common/Translation";
import { OptionType, PageBundleStatus } from "./types";

interface PageBundleGeneratorProps {
  selectedMessages: string[];
  bundleStatus: PageBundleStatus;
  setShowBundleBox: (show: boolean) => void;
  setBundleStatus: (status: PageBundleStatus) => void;
  setBundleName: (name: string) => void;
}

const PageBundleGenerator: React.FC<PageBundleGeneratorProps> = ({
  selectedMessages,
  bundleStatus,
  setShowBundleBox,
  setBundleStatus,
  setBundleName,
}) => {
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );
  const [dropValue, setDropValue] = useState<string | null>(null);
  const selectOptions = [
    { label: translations.Create_Snap_Bundle_from_all, value: "all" },
    { label: translations.Create_Snap_Bundle_from_selected, value: "selected" },
  ];

  const handleSelectChange = (
    selectedOption: SingleValue<OptionType>,
  ): void => {
    setDropValue(selectedOption ? selectedOption.value : null);
  };

  const handleCreateButtonClick = async () => {
    if (!dropValue) return;

    // Generate the bundle name
    const now = new Date();
    const formattedDate = now.toISOString().split("T")[0];
    const formattedTime = now.toTimeString().split(" ")[0].slice(0, 5);
    const bundleName = `MessageBank ${formattedDate} ${formattedTime}`;
    setBundleName(bundleName);

    setShowBundleBox(true);
    setBundleStatus({ inProgress: true, completed: false, failed: false });

    try {
      if (dropValue === "all") {
        // TODO: Replace with actual API call
        console.log("Creating bundle for all messages with name:", bundleName);
      } else if (dropValue === "selected") {
        // TODO: Replace with actual API call
        console.log(
          "Creating bundle for selected messages with name:",
          bundleName,
          selectedMessages,
        );
      }
    } catch (error) {
      console.error("Error creating bundle:", error);
      setBundleStatus({ inProgress: false, completed: false, failed: true });
    }
  };

  return (
    <div className="col-xs-12 col-md-4 col-md-push-4">
      <div className="margin-top-lg input-group bundle-generator-wrapper">
        <Select
          options={selectOptions}
          value={selectOptions.find((opt) => opt.value === dropValue)}
          onChange={handleSelectChange}
          placeholder={translations.Create_Snap_Bundle_from}
          isDisabled={bundleStatus.inProgress}
          classNamePrefix="custom-select"
          isSearchable={false}
        />
        <button
          className="btn btn-primary form-control input-group-addon bundle-generator-button"
          disabled={!dropValue || bundleStatus.inProgress}
          onClick={handleCreateButtonClick}
        >
          <Translation translate="Create" textonly={true} />
        </button>
      </div>
    </div>
  );
};

export default PageBundleGenerator;
