export const redirects = [
  // support

  { from: "Subscription/VerifyProfessional", to: "/morestuff/professionals" },
  { from: "support/snap-word-lists", to: "/support/snap-resources" },
  {
    from: "/Support/AccessibleLiteracyLearning",
    to: "/support/accessible-literacy-learning",
  },
  {
    from: "/Support/BoardmakerStudentCenterApp",
    to: "/support/boardmaker-student-center-app",
  },
  {
    from: "/Support/ClassicTobiiGazeInteractionSoftware",
    to: "/support/classic-tobii-gaze-interaction-software",
  },
  { from: "/Support/Communicator5", to: "/support/communicator-5" },
  { from: "/Support/GazePoint", to: "/support/gaze-point" },
  { from: "/Support/GazeViewer", to: "/support/gaze-viewer" },
  { from: "/Support/MirrorAndroid", to: "/support/mirror-for-android" },
  { from: "/Support/PCEyeCC", to: "/support/pceye" },
  { from: "/Support/TDSnap", to: "/support/td-snap" },
  { from: "/Support/SnapScene", to: "/support/snap-scene" },
  { from: "/Support/WindowsControl", to: "/support/windows-control" },
  { from: "/Support/Communicator4", to: "/support/communicator-4" },
  { from: "/Support/LiterAACy", to: "/support/liter-aacy" },
  { from: "/Support/SonoKey", to: "/support/sono-key" },
  { from: "/Support/SonoPrimo", to: "/support/sono-primo" },

  { from: "/Support/SonoFlex", to: "/support/sono-flex" },
  { from: "/Support/SonoLexis", to: "/support/sono-lexis" },
  { from: "/Support/SonoScribe", to: "/support/sono-scribe" },
  {
    from: "/Support/OnscreenKeyboardApp",
    to: "/support/on-screen-keyboard-app",
  },
  { from: "/Support/Series5Converter", to: "/support/series-5-converter" },
  { from: "/Support/TalkTogether", to: "/support/talk-together" },
  { from: "/Support/PCSSymbols", to: "/support/pcs-symbols" },
  { from: "/Support/TabletTalk", to: "/support/tablet-talk" },
  {
    from: "/Support/WindowsCompatibility",
    to: "/support/windows-compatibility",
  },

  { from: "/Support/Eula", to: "/support/eula" },
  {
    from: "/Support/professionalresources",
    to: "/support/professional-resources",
  },

  { from: "/Support/snap-resources", to: "/support/snap-resources" },
  { from: "/support/snap-word-lists", to: "/support/snap-resources" },

  // resources

  { from: "/resources/mythsandresearch", to: "/resources/myths-and-research" },

  //store

  { from: "/Store/TDSnap", to: "/store/snap" },
  {
    from: "/store/SnapCoreFirstPerpetual",
    to: "/store/snap-core-first-perpetual",
  },
  { from: "/Store/Communicator5", to: "/store/communicator" },
  { from: "/Store/SnapScene", to: "/store/snap-scene" },
  { from: "/Store/EarlyIntervention", to: "/store/early-intervention" },
  { from: "/Store/GazeViewer", to: "/store/gaze-viewer" },
  { from: "/Store/SpeechCasePodd", to: "/store/speech-case-podd" },
].map(({ from, to }) => ({ from: from.toLowerCase(), to: to.toLowerCase() }));
