import { React, useEffect } from "react";

const FastSpringProvider = ({ user, storefrontLink, productPath }) => {
  useEffect(() => {
    if (document.getElementById("fsc-api")) return;

    const script = document.createElement("script");
    script.id = "fsc-api";
    script.src =
      "https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.3/fastspring-builder.min.js";
    script.type = "text/javascript";
    script.dataset.storefront = storefrontLink;
    script.dataset.popupClosed = "onPopupClose";
    document.body.appendChild(script);

    script.onload = () => {
      if (window.fastspring) {
        const session = {
          reset: true,
          paymentContact: {
            email: user?.email || "defaultEmail@example.com",
            firstName: user?.firstName || "John",
            lastName: user?.lastName || "Doe",
            address: {
              country: user?.address.country || "US",
              postalCode: user?.address.zipCode || "10001",
            },
          },
          tags: {
            ssoid: user?.ssoid || "defaultSSOId",
          },
          products: [{ path: productPath }],
        };

        window.fastspring.builder.push(session);
      }
    };
  }, [user, productPath]);

  return null;
};

export default FastSpringProvider;
