import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { getPageSetData } from "@services";
import FilterComponent from "./Filter";
import Breadcrumb from "@common/Breadcrumb";
import RawHTML from "@common/RawHTML/Index";
import ResultComponent from "./Results";
import { Pagination, State, LanguageData, Filters, SearchResult } from "types";
import toast from "react-hot-toast";

const PagesetCentralHome: React.FC = () => {
  const initialFilters: Filters = {
    text: "",
    software: [],
    category: [],
    languageCode: [],
    page: 0,
  };

  const { translations } = useSelector(
    (state: { translations: State }) => state.translations,
  );
  const { languageCode } = useSelector(
    (state: { languageData: LanguageData }) => state.languageData,
  );

  const [searchInProgress, setSearchInProgress] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<SearchResult>();
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [pagination, setPagination] = useState<Pagination>({
    itemOffset: 0,
    pageNumber: 0,
  });

  const handleFilterChange = useCallback(
    (filterType: keyof Filters, filterValue: string | number) => {
      setSearchInProgress(true);
      setFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters };
        if (filterType === "page") {
          updatedFilters.page = filterValue as number;
        } else if (filterType === "text") {
          updatedFilters.text = filterValue as string;
        } else {
          const filterArray = updatedFilters[filterType] as string[];
          updatedFilters[filterType] = filterArray.includes(
            filterValue as string,
          )
            ? filterArray.filter((f) => f !== filterValue)
            : [...filterArray, filterValue];
        }
        return updatedFilters;
      });
    },
    [],
  );

  const handleResetFilters = useCallback(() => {
    setFilters(initialFilters);
    setSearchInProgress(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getPageSetData(languageCode, filters);
        setSearchResult(result);
      } catch (error) {
        toast.error(
          translations.An_error_occurred_while_processing_your_request,
        );
      } finally {
        setSearchInProgress(false);
      }
    };

    const timerId = setTimeout(fetchData, 500);
    return () => clearTimeout(timerId);
  }, [filters, languageCode]);

  if (!translations) return null;

  return (
    <>
      <div className="container-full">
        <div className="container myStuff-header">
          <h1>
            {translations.Pageset_Central}
            <sup
              className="icon icon-pageset-central icon-34"
              style={{ float: "none" }}
              title="Pageset Central"
            ></sup>
          </h1>
          <Breadcrumb
            items={[
              {
                label: translations.Pageset_Central,
              },
            ]}
          />
        </div>
        <div className="container" style={{ paddingBottom: "0" }}>
          <div className="intro-section">
            <RawHTML htmlContent={translations.Pageset_Central_Intro} />
          </div>
        </div>
      </div>
      <div className="container results" style={{ paddingBottom: "0" }}>
        <div
          id="pagesetResults"
          className="row"
          style={{ minHeight: "1000px" }}
        >
          <div className="col-md-4">
            <FilterComponent
              translations={translations}
              filters={filters}
              handleFilterChange={handleFilterChange}
              handleResetFilters={handleResetFilters}
              searchInProgress={searchInProgress}
              searchResult={searchResult}
            />
          </div>
          <div className="col-md-8">
            {searchInProgress ? (
              <h2>
                {translations.Pagesets_Message_Loading}{" "}
                <i className="fa fa-refresh fa-spin"></i>
              </h2>
            ) : (
              <ResultComponent
                itemsPerPage={12}
                translations={translations}
                searchResult={searchResult}
                handleFilterChange={handleFilterChange}
                setPagination={setPagination}
                pagination={pagination}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PagesetCentralHome;
