import React from "react";
import ReactPaginate from "react-paginate";
import Select, { SingleValue } from "react-select";
import { Filters, Message, PageOptionType } from "../types";
import Translation from "@common/Translation";
import { useSelector } from "react-redux";
import { RootState } from "store";

interface PaginationProps {
  totalMessagesCount: number;
  itemsPerPage: number;
  totalPageCount: number;
  currentPage: number;
  filters: Filters;
  messages: Message[];
  handleFilterChange: (updatedFilters: Partial<Filters>) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalMessagesCount,
  itemsPerPage,
  totalPageCount,
  currentPage,
  filters,
  messages,
  handleFilterChange,
}) => {
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );

  const onPageChange = (selectedPage: number) => {
    handleFilterChange({
      pagination: {
        page: selectedPage + 1,
        pageSize: itemsPerPage,
      },
    });
  };

  const getMessageNumbers = () => {
    const start = currentPage ? itemsPerPage * (currentPage - 1) + 1 : 0;
    const end = totalMessagesCount ? messages.length + start - 1 : 0;
    return { start, end };
  };

  const { start, end } = getMessageNumbers();

  const pageOptions: PageOptionType[] = [
    {
      label: translations.MYTD_showLbl + " 25",
      value: 25,
    },
    {
      label: translations.MYTD_showLbl + " 50",
      value: 50,
    },
    {
      label: translations.MYTD_showLbl + " 100",
      value: 100,
    },
    {
      label: translations.MYTD_showLbl + " 250",
      value: 250,
    },
    {
      label: translations.MYTD_showLbl + " 500",
      value: 500,
    },
    {
      label: translations.MYTD_showLbl + " 750",
      value: 750,
    },
    {
      label: translations.MYTD_showAllLbl,
      value: 999999,
    },
  ];

  return (
    <div className="mb-pagination-container">
      <h5>
        <span>
          <Translation translate="MYTD_showingLabel" />{" "}
        </span>
        <strong>{start}</strong>
        <span> - </span>
        <strong>{end}</strong>
        <span>
          {" "}
          <Translation translate="MYTD_ofLabel" />{" "}
        </span>
        <strong>{totalMessagesCount}</strong>
        <span>
          {" "}
          <Translation translate="MYTD_resultsLabel" />
        </span>
      </h5>
      <Select
        options={pageOptions}
        value={pageOptions.find(
          (option) => option.value === filters.pagination.pageSize,
        )}
        placeholder={translations.MYTD_showAllLbl}
        onChange={(selectedOption) => {
          const option = selectedOption as SingleValue<PageOptionType>;
          if (option) {
            handleFilterChange({
              pagination: {
                pageSize: option.value,
                page: filters.pagination.page,
              },
            });
          }
        }}
        classNamePrefix="custom-select"
        isSearchable={false}
      />
      <ReactPaginate
        previousLabel="<"
        nextLabel=">"
        breakLabel="..."
        pageCount={totalPageCount}
        forcePage={currentPage - 1}
        onPageChange={(data) => onPageChange(data.selected)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        containerClassName="pagination mb-pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        activeClassName="active"
      />
    </div>
  );
};

export default Pagination;
