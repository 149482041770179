import React, { useEffect, useState } from "react";
import Translation from "@common/Translation";
import { Message } from "./types";
import { getMessages } from "@services";
import { useSelector } from "react-redux";
import { State } from "types";

const PrintMessages: React.FC = () => {
  const { languageCode } = useSelector((state: State) => state.languageData);
  const { userDetail } = useSelector((state: State) => state.user);
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await getMessages(languageCode, {
          sort: "-created",
          search: null,
          searchField: "all",
          pagination: { page: 1, pageSize: 99999999 },
          filter: { verified: null },
        });
        const fetchedMessages = data._Embedded.recordedMessages;
        setMessages(fetchedMessages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [languageCode]);

  const currentDate = new Date().toLocaleString();

  const handlePrint = () => {
    window.print();
  };

  const renderTable = () => (
    <table className="table table-striped table-bordered table-sm">
      <thead>
        <tr>
          <th>
            <Translation translate="File" />
          </th>
          <th>
            <Translation translate="MYTD_MBListText" />
          </th>
          <th>
            <Translation translate="MYTD_MBListTags" />
          </th>
        </tr>
      </thead>
      <tbody>
        {messages.map((message) => (
          <tr key={message.id}>
            <td>{message.fileName}</td>
            <td>{message.transcription.text || ""}</td>
            <td>
              {message.tags.length > 0
                ? message.tags.map((tag) => tag.name).join(", ")
                : ""}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="container-fluid printable-area">
      <div className="print-header">
        <h3>
          {userDetail?.firstName || ""} {userDetail?.lastName || ""} ·{" "}
          {currentDate}
        </h3>
        <button
          className="btn btn-print no-print"
          onClick={handlePrint}
          title="Print"
          style={{ backgroundColor: "transparent", border: "none" }}
        >
          <img
            src="/assets/images/print.svg"
            alt="Print"
            style={{ height: "24px" }}
          />
        </button>
      </div>
      {loading ? (
        <Translation translate="MYTD_MBMsgLoading" />
      ) : (
        <div className="print-table">{renderTable()}</div>
      )}
    </div>
  );
};

export default PrintMessages;
