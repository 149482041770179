import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getAvailableUpdates } from "@services";
import toast from "react-hot-toast";
import { RootState } from "store";

interface Update {
  product: string;
  link: string;
  linkRef: string;
}
interface AvailableUpdate {
  title: string;
  updates: Update[];
}
interface AvailableUpdatesModalProps {
  resources: {
    Updates_available_: string;
    Review_All: string;
    Create_account: string;
    Log_in: string;
    Close: string;
  };
}

const AvailableUpdatesModal: React.FC<AvailableUpdatesModalProps> = ({
  resources,
}) => {
  const { languageCode } = useSelector(
    (state: { languageData: { languageCode: string } }) => state.languageData,
  );
  const [availableUpdate, setAvailableUpdate] = useState<AvailableUpdate>({
    title: "",
    updates: [],
  });
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAvailableUpdates(languageCode);
        setAvailableUpdate(data);
      } catch (error) {
        toast.error(
          translations.An_error_occurred_while_processing_your_request,
        );
      }
    };

    fetchData();
  }, [languageCode]);
  return (
    <>
      {availableUpdate && (
        <div
          data-testid="available-updates-modal"
          id="availableUpdatesModal"
          className="modal fade"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h2 className="modal-title">{availableUpdate.title}</h2>
              </div>
              <div
                className="modal-body updates-messaging"
                style={{ paddingBottom: "0px" }}
              >
                {availableUpdate.updates.map((update, index) => (
                  <p key={`${update.product}-${index}`}>
                    <strong>{update.product}</strong>
                    <a href={update.link} target="_blank" rel="noreferrer">
                      {update.linkRef}
                    </a>
                  </p>
                ))}
              </div>
              <div className="modal-footer" style={{ marginTop: "0px" }}>
                <button
                  type="button"
                  className="btn btn-lg btn-primary"
                  data-dismiss="modal"
                  data-testid="close-button"
                >
                  {resources.Close}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AvailableUpdatesModal;
