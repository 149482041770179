import axios from "axios";
import { redirectToLogin } from "@services";

const baseService = axios.create({
  baseURL: `/api/`,
  timeout: 1200000,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "*/*",
    "Accept-Language": navigator.language,
  },
});

baseService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      redirectToLogin();
    }
    return Promise.reject(error);
  },
);

export const fetchData = async (endpoint, options = {}) => {
  try {
    const { responseType = "json", signal, ...restOptions } = options;

    const config = {
      ...restOptions,
      responseType,
      signal,
    };

    const response = await baseService.get(endpoint, config);

    if (responseType === "blob" || responseType === "arraybuffer") {
      return response;
    }

    return response.data;
  } catch (error) {
    if (error.name === "AbortError") {
      console.warn("Request was canceled:", error);
    } else {
      console.error("Error fetching data:", error);
    }
    throw error;
  }
};

export const postData = async (endpoint, payload, options = {}) => {
  try {
    const {
      responseType = "json",
      signal,
      headers: customHeaders = {},
    } = options;

    const isFormData = payload instanceof FormData;
    const headers = {
      "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      ...customHeaders,
    };

    const config = {
      headers,
      responseType,
      signal,
    };

    const response = await baseService.post(endpoint, payload, config);

    if (responseType === "blob" || responseType === "arraybuffer") {
      return response;
    }

    return response.data;
  } catch (error) {
    if (error.name === "AbortError") {
      console.warn("Request was canceled:", error);
    } else {
      console.error("Error posting data:", error);
    }
    throw error;
  }
};

export const deleteData = async (endpoint, payload) => {
  try {
    const config = payload ? { data: payload } : {};
    const response = await baseService.delete(endpoint, config);
    return response.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

export const putData = async (endpoint, payload, options = {}) => {
  try {
    const isFormData = payload instanceof FormData;

    const response = await baseService.put(endpoint, payload, {
      ...options,
      headers: {
        ...(options.headers || {}),
        ...(isFormData ? {} : { "Content-Type": "application/json" }),
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
};

export default baseService;
