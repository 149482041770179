import React from "react";
import { Modal } from "react-bootstrap";

interface ActionModalProps {
  show: boolean;
  title: string;
  body: React.ReactNode;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
  onCancel: () => void;
  loading?: boolean;
}

const ActionModal: React.FC<ActionModalProps> = ({
  show,
  title,
  body,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  loading = false,
}) => {
  return (
    <Modal
      show={show}
      onHide={onCancel}
      backdrop="static"
      className={show ? "in" : ""}
      data-testid="action-modal"
    >
      <Modal.Header>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onCancel}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h2>{title}</h2>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <button className="btn btn-default" onClick={onCancel}>
          {cancelText}
        </button>
        <button
          className="btn btn-primary"
          onClick={onConfirm}
          disabled={loading}
        >
          {loading ? (
            <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true" />
          ) : null}
          {confirmText}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ActionModal;
