import React from "react";
import { useSelector } from "react-redux";
import { redirectToLogin } from "@services";

const BuyButton = ({ className, imgSrc, altText, text, link }) => {
  const { isAuthenticated } = useSelector((state) => state.user);
  const storeUrl = `/api/v1${link}`;
  return (
    <a
      href={storeUrl}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      data-testid="buy-button"
      onClick={(event) => {
        if (!isAuthenticated) {
          event.preventDefault();
          redirectToLogin();
        }
      }}
    >
      {imgSrc && <img src={imgSrc} alt={altText} />}
      {text}
    </a>
  );
};

export default BuyButton;
