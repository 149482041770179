import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Breadcrumb from "../../../common/Breadcrumb";
import MoreStuffProForm from "./MoreStuffProfForm";
import { getUserProfile } from "@services";
import { redirectToEdit } from "@services";
import toast from "react-hot-toast";

const MoreStuffProfessionals = () => {
  const { translations } = useSelector((state) => state.translations);
  const { languageCode } = useSelector((state) => state.languageData);
  const [userProfile, setUserProfile] = useState([]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const data = await getUserProfile(languageCode);
        setUserProfile(data);
      } catch (error) {
        toast.error(
          translations.An_error_occurred_while_processing_your_request,
        );
      }
    };
    fetchUserProfile();
  }, [languageCode, translations]);

  const handleRedirect = (event) => {
    event.preventDefault();
    redirectToEdit();
  };

  return (
    <>
      <div className="container-full">
        <div className="container myStuff-header fade-block fade-in">
          <h1>{translations.Tobii_Dynavox_for_Professionals}</h1>
          <div className="fade-block fade-in">
            <Breadcrumb
              items={[{ label: translations.Professionals }]}
              path="professionals"
            ></Breadcrumb>
          </div>
        </div>
      </div>
      <div className="container fade-block fade-in">
        <h2>{translations.MYTD_verifyProLbl}</h2>
        <div>
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: translations.MYTD_verifyProDesc1,
              }}
            />
          </div>
          <span id="form-errors" className="field-validation-error">
            <ul className="margin-bottom-md"></ul>
          </span>

          <MoreStuffProForm
            userProfile={userProfile}
            onClickChange={handleRedirect}
          ></MoreStuffProForm>
        </div>
      </div>
    </>
  );
};
export default MoreStuffProfessionals;
