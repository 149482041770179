import React from "react";
import { Link } from "react-router-dom";
import Translation from "@common/Translation";
import { Filters, Message, PageBundleStatus } from "../types";
import FiltersDropdown from "./FiltersDropdown";
import Search from "./Search";
import Pagination from "./Pagination";
import PageBundleGenerator from "../PageBundleGenerator";

interface MessageListControlsProps {
  filters: Filters;
  totalPageCount: number;
  totalMessagesCount: number;
  messages: Message[];
  bundleStatus: PageBundleStatus;
  handleFilterChange: (updatedFilters: Partial<Filters>) => void;
  setShowBundleBox: (show: boolean) => void;
  setBundleStatus: (status: PageBundleStatus) => void;
  setBundleName: (name: string) => void;
}

const MessageListControls: React.FC<MessageListControlsProps> = ({
  filters,
  totalPageCount,
  totalMessagesCount,
  messages,
  bundleStatus,
  handleFilterChange,
  setShowBundleBox,
  setBundleStatus,
  setBundleName,
}) => {
  const selectedMessages = messages.filter((message) => message.selected);
  return (
    <>
      <div className="container-fluid padding-bottom-reset search-bar">
        <div className="row">
          <Search filters={filters} handleFilterChange={handleFilterChange} />
          <PageBundleGenerator
            selectedMessages={selectedMessages.map((msg) => msg.id)}
            setShowBundleBox={setShowBundleBox}
            bundleStatus={bundleStatus}
            setBundleStatus={setBundleStatus}
            setBundleName={setBundleName}
          />
        </div>
      </div>
      <div className="controls-container margin-top-md">
        <div className="controls-left">
          <Link
            to="/print-messages"
            target="_blank"
            rel="noopener noreferrer"
            className="button-mb btn"
          >
            <Translation translate="Print_all_messages" />
          </Link>
          <FiltersDropdown
            handleFilterChange={handleFilterChange}
            filters={filters}
          />
        </div>

        <Pagination
          totalPageCount={totalPageCount}
          totalMessagesCount={totalMessagesCount}
          itemsPerPage={filters.pagination.pageSize}
          currentPage={filters.pagination.page}
          messages={messages}
          filters={filters}
          handleFilterChange={handleFilterChange}
        />
      </div>
    </>
  );
};

export default MessageListControls;
