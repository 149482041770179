import { AxiosResponse } from "axios";
import toast from "react-hot-toast";

/**
 * Handles downloading a zip file from a response.
 * @param response API response containing the file data.
 * @param defaultFileName Default filename to use if content-disposition header is missing.
 */
export const handleFileDownload = (
  response: AxiosResponse<Blob>,
  defaultFileName: string,
) => {
  try {
    const blob = new Blob([response.data], { type: "application/zip" });
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;

    // Generate filename with current date and time
    const now = new Date();
    const formattedDate = now.toISOString().split("T")[0];
    const formattedTime = now.toTimeString().split(" ")[0].replace(/:/g, "-");
    const generatedFileName = `${defaultFileName}_${formattedDate}_${formattedTime}.zip`;

    // Try to extract filename from header
    const contentDisposition = response.headers?.["content-disposition"];
    const fileName = contentDisposition
      ? contentDisposition.split("filename=")[1]?.replace(/['"]/g, "")
      : generatedFileName;

    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    toast.success("Download complete!");
  } catch (error) {
    console.error("Error handling file download:", error);
    toast.error("An error occurred while processing your request.");
  }
};
