import { postData, fetchData, putData, deleteData } from "./base-service";

const MESSAGE_BANK_API_ENDPOINTS = {
  fetchMessages: (langCode) => `/v1/message-banking/messages?uic=${langCode}`,
  fetchLanguages: (langCode) => `/v1/message-banking/languages?uic=${langCode}`,
  addMessage: () => `/v1/message-banking/add`,
  uploadMessage: (id) => `/v1/message-banking/upload/${id}`,
  downLoadAll: () => `/v1/message-banking/messages`,
  downloadSelected: () => `/v1/message-banking/selected-messages`,
  tags: () => `/v1/message-banking/tags`,
  play: (id) => `/v1/message-banking/play/${id}`,
  delete: (id) => `/v1/message-banking/${id}`,
  update: (id) => `/v1/message-banking/${id}`,
};

export const getMessages = (langCode, payload) => {
  return postData(MESSAGE_BANK_API_ENDPOINTS.fetchMessages(langCode), payload);
};

export const getMessageBankLanguages = (langCode) => {
  return fetchData(MESSAGE_BANK_API_ENDPOINTS.fetchLanguages(langCode));
};

export const postAddMessage = (payload, headers = {}) => {
  return postData(MESSAGE_BANK_API_ENDPOINTS.addMessage(), payload, {
    headers,
  });
};

export const putUploadMessage = async (id, file) => {
  const formData = new FormData();
  formData.append("recordedMessage", file);

  return putData(MESSAGE_BANK_API_ENDPOINTS.uploadMessage(id), formData);
};

export const getTags = () => {
  return fetchData(MESSAGE_BANK_API_ENDPOINTS.tags());
};

export const downloadAllMessages = (signal) => {
  return fetchData(MESSAGE_BANK_API_ENDPOINTS.downLoadAll(), {
    responseType: "blob",
    signal, // Pass the abort signal for cancellation
  });
};

export const downloadSelectedMessages = (payload, signal) => {
  return postData(MESSAGE_BANK_API_ENDPOINTS.downloadSelected(), payload, {
    responseType: "blob",
    signal, // Pass the abort signal for cancellation
  });
};

export const getAudio = (id) => {
  return fetchData(MESSAGE_BANK_API_ENDPOINTS.play(id), {
    responseType: "arraybuffer",
  });
};

export const deleteMessage = (id) => {
  return deleteData(MESSAGE_BANK_API_ENDPOINTS.delete(id));
};

export const updateMessage = (id, payload) => {
  return putData(MESSAGE_BANK_API_ENDPOINTS.update(id), payload);
};
