import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getProfessionalResources } from "@services";
import Breadcrumb from "@common/Breadcrumb";
import toast from "react-hot-toast";

const ProfessionalResources = () => {
  const { languageCode } = useSelector((state) => state.languageData);
  const [resourcesData, setResourcesData] = useState({});
  const { translations } = useSelector((state) => state.translations);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getProfessionalResources(languageCode);
        setResourcesData(data);
      } catch (error) {
        toast.error(
          translations.An_error_occurred_while_processing_your_request,
        );
      }
    };

    fetchData();
  }, [languageCode]);

  return (
    <>
      {resourcesData && (
        <>
          <div className="container-full">
            <div className="container myStuff-header">
              <h1>{resourcesData.title}</h1>
              <Breadcrumb items={[{ label: resourcesData.title }]} />
            </div>
          </div>
          <div className="container">
            <div className="resources margin-top-lg">
              {resourcesData.resourcesList &&
                resourcesData.resourcesList.map((item, index) => (
                  <div key={`${item.title}-${index}`}>
                    <h2>{item.title}</h2>
                    <div
                      className="panel-group"
                      data-group={resourcesData.title}
                      data-section={item.title}
                    >
                      {item.links.map((linkItem, index) => (
                        <div
                          key={`${linkItem.mainUrlText}-${index}`}
                          className="panel panel-default"
                        >
                          <div className="panel-body">
                            <div
                              className={`${
                                linkItem.mainUrl.endsWith(".pdf")
                                  ? "file-type-pdf"
                                  : "file-type-html"
                              }`}
                              title={linkItem.resourceType}
                            ></div>
                            <div className="resource">
                              <a
                                href={linkItem.mainUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {linkItem.mainUrlText}
                              </a>
                              {linkItem.availability && (
                                <>
                                  <br />
                                  {linkItem.availability.title}{" "}
                                  {linkItem.availability.languages.map(
                                    (langItem, index) => (
                                      <React.Fragment
                                        key={`${langItem.language}-${index}`}
                                      >
                                        <a
                                          href={langItem.url}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {langItem.language}
                                        </a>
                                        {index <
                                          linkItem.availability.languages
                                            .length -
                                            1 && ", "}
                                      </React.Fragment>
                                    ),
                                  )}
                                </>
                              )}
                              {linkItem.description && (
                                <span className="desc">
                                  <span>{linkItem.description}</span>
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ProfessionalResources;
