import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import RawHTML from "@common/RawHTML/Index";
import Breadcrumb from "@common/Breadcrumb";
import { getDownloadPageByName } from "@services";
import SystemDownloadButton from "../../Store/Common/SystemDownloadButton";
import DownloadDropdown from "./DownloadDropdown";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

const DownloadInner = ({ pageKey }) => {
  const { languageCode } = useSelector((state) => state.languageData);
  const { translations } = useSelector((state) => state.translations);
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    const fetchDownloadPage = async () => {
      try {
        const pageData = await getDownloadPageByName(pageKey, languageCode);
        setPageData(pageData);
      } catch (error) {
        toast.error(
          translations.An_error_occurred_while_processing_your_request,
        );
      }
    };

    fetchDownloadPage();
  }, [languageCode, pageKey]);

  return (
    <>
      {translations && pageData && (
        <>
          <div className="container-full">
            <div className="container myStuff-header">
              <h1 dangerouslySetInnerHTML={{ __html: pageData.title }} />
              <Breadcrumb
                items={[
                  {
                    href: "/support/downloads",
                    label: translations.Downloads,
                  },
                  { label: pageData.subTitle },
                ]}
              />
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="db-container">
                <div className="container" id="InstallerDownloads">
                  <div className="panel list-group primary-dl">
                    {pageData.versions &&
                      pageData.versions.map((item, index) => (
                        <React.Fragment key={`${item.title}_${index}_`}>
                          <div className="row list-group-item">
                            <div className="col-sm-1 hidden-xs">
                              <div className="icon icon-download-installer icon-56 hidden-xs pull-right"></div>
                            </div>
                            <div className="col-sm-11">
                              {pageData.versions.length === 1 ? (
                                <h3
                                  dangerouslySetInnerHTML={{
                                    __html: item.title,
                                  }}
                                ></h3>
                              ) : (
                                <h3>
                                  <Link
                                    to={`#${index}`}
                                    className=""
                                    data-toggle="collapse"
                                    data-parent="#InstallerDownloads"
                                  >
                                    {item.title}
                                  </Link>
                                </h3>
                              )}

                              <div className="row">
                                <div className="col-sm-9">
                                  <RawHTML htmlContent={item.instructions} />
                                </div>
                                <div className="col-sm-3 hidden-xs">
                                  <p className="text-right">
                                    {item.downloadUrls.length}{" "}
                                    {item.downloadUrls.length > 1
                                      ? translations.Installers
                                      : translations.Installer}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id={index}
                            className={
                              pageData.versions.length > 1 ? "collapse" : ""
                            }
                          >
                            <div className="row">
                              <div className="col-sm-offset-1 col-sm-11">
                                <div className="well">
                                  <RawHTML htmlContent={item.details} />
                                  {item.details && <hr />}
                                  {item.downloadUrls.length < 3 ? (
                                    <div className="track-clicks">
                                      {item.downloadUrls.map(
                                        ({ system, urls }) => {
                                          return urls.length > 1 ? (
                                            <DownloadDropdown
                                              title={item.title}
                                              translations={translations}
                                              key={system}
                                              listItems={urls.map(
                                                ({ language, url }) => {
                                                  return {
                                                    label: language,
                                                    url: url,
                                                  };
                                                },
                                              )}
                                            />
                                          ) : (
                                            <SystemDownloadButton
                                              url={
                                                urls.length > 0
                                                  ? urls[0].url
                                                  : ""
                                              }
                                              system={system}
                                              translations={translations}
                                              key={system}
                                            />
                                          );
                                        },
                                      )}
                                    </div>
                                  ) : (
                                    <DownloadDropdown
                                      title={item.title}
                                      translations={translations}
                                      listItems={item.downloadUrls.map(
                                        ({ system, urls }) => {
                                          return {
                                            label: system,
                                            url:
                                              urls.length > 0
                                                ? urls[0].url
                                                : "",
                                          };
                                        },
                                      )}
                                    />
                                  )}
                                  {(!!item.notes.length ||
                                    item.requirements ||
                                    item.compatibility ||
                                    item.notSupported) && <hr />}
                                  {item.notes.map((note, index) => (
                                    <div
                                      className="note"
                                      key={`${note}_${index}`}
                                    >
                                      <RawHTML htmlContent={note} />
                                    </div>
                                  ))}
                                  {item.requirements && (
                                    <div className="note">
                                      <RawHTML
                                        htmlContent={item.requirements}
                                      />
                                    </div>
                                  )}
                                  {item.compatibility?.fullCompatibility
                                    .length > 0 && (
                                    <div className="note">
                                      <strong>
                                        {translations.Full_compatibility}
                                      </strong>
                                      <ul>
                                        {item.compatibility.fullCompatibility.map(
                                          (system) => (
                                            <li key={system}>{system}</li>
                                          ),
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                  {item.compatibility?.partialCompatibility
                                    .length > 0 && (
                                    <div className="note">
                                      <strong>
                                        {translations.Partial_compatibility}
                                      </strong>
                                      <ul>
                                        {item.compatibility.partialCompatibility.map(
                                          (system) => (
                                            <li key={system.device}>
                                              {system.device}
                                              <br />
                                              <em>{system.reason}</em>
                                            </li>
                                          ),
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                  {item.compatibility?.notSupported.length >
                                    0 && (
                                    <div className="note">
                                      <strong>
                                        {translations.Not_Supported}
                                      </strong>{" "}
                                      {item.compatibility.notSupported.join(
                                        ", ",
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DownloadInner;
