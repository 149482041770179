import Translation from "@common/Translation";
import React from "react";
import { PageBundleStatus } from "./types";
import { useSelector } from "react-redux";
import { RootState } from "store";

const formatTranslation = (
  template: string,
  replacements: Record<string, string>,
): { __html: string } => {
  let html = template;
  Object.entries(replacements).forEach(([key, value]) => {
    html = html.replace(new RegExp(`\\{${key}\\}`, "g"), value);
  });
  return { __html: html };
};

interface BundleGeneratorMessageProps {
  bundleStatus: PageBundleStatus;
  bundleName: string;
  setShowBundleBox: (show: boolean) => void;
}

const BundleGeneratorMessage: React.FC<BundleGeneratorMessageProps> = ({
  bundleStatus,
  bundleName,
  setShowBundleBox,
}) => {
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );

  return (
    <div className="row reporting-wrapper">
      <div className="well col-xs-12">
        <div>
          <button
            type="button"
            className="close"
            onClick={() => setShowBundleBox(false)}
          >
            <span>&times;</span>
          </button>
        </div>

        <div className="bundle-message-text">
          {bundleStatus.inProgress && (
            <div>
              <Translation translate="Snap_Bundle_being_created" textonly />
              <strong>
                {/* TODO: update this number when I get it from the API */}
                0% <Translation translate="MYTD_MBUploadComplete" />
              </strong>
            </div>
          )}

          {bundleStatus.completed && (
            <div
              dangerouslySetInnerHTML={formatTranslation(
                translations.Snap_Bundle_job_complete,
                {
                  0: bundleName,
                },
              )}
            />
          )}

          {bundleStatus.failed && (
            <div
              dangerouslySetInnerHTML={formatTranslation(
                translations.Snap_Bundle_job_failed,
                {
                  0: "https://www.tobiidynavox.com/blogs/support-articles/tagged/message-banking",
                  1: "https://www.tobiidynavox.com/pages/customer-support",
                },
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BundleGeneratorMessage;
