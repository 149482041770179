import { deleteData, fetchData, postData } from "./base-service";

const USER_API_ENDPOINTS = {
  userProfile: (langCode) => `/v1/user/profile?uic=${langCode}`,
  userRoles: () => `/v1/user/roles`,
  userStuff: (langCode) => `/v1/products?uic=${langCode}`,
  notificationCount: (langCode) =>
    `/v1/notifications/active-count?uic=${langCode}`,
  checkData: (langCode) => `/v1/user/has-usage-data?uic=${langCode}`,
  userProfileUpdate: () => `/v1/user/profile`,
  userDevices: () => `/v1/user/user-devices`,
  usageData: () => `/v1/user/usage-data`,
  BMODistCode: (code) => `/v1/user/boardmaker-code?code=${code}`,
  exportUsageData: () => `/v1/user/export-usage-data`,
  antiForgeryToken: () => `/v1/antiforgery/token`,
};
export const checkBMODistCode = (code) => {
  return fetchData(USER_API_ENDPOINTS.BMODistCode(code));
};

export const deleteBMODistCode = (code) => {
  return deleteData(USER_API_ENDPOINTS.BMODistCode(code));
};

export const editProfile = (userData) => {
  return postData(USER_API_ENDPOINTS.userProfileUpdate(), userData);
};

export const checkForData = async (langCode) =>
  fetchData(USER_API_ENDPOINTS.checkData(langCode));

export const getUserDevices = async () =>
  fetchData(USER_API_ENDPOINTS.userDevices());

export const getUsageData = (payload) => {
  return postData(USER_API_ENDPOINTS.usageData(), payload);
};

export const exportUsageData = (payload) => {
  return postData(USER_API_ENDPOINTS.exportUsageData(), payload);
};

export const getUserProfile = async (langCode) =>
  fetchData(USER_API_ENDPOINTS.userProfile(langCode));

export const getUserRoles = async () =>
  fetchData(USER_API_ENDPOINTS.userRoles());

export const getUserStuff = async (langCode) =>
  fetchData(USER_API_ENDPOINTS.userStuff(langCode));

export const getUserNotificationCount = async (langCode) =>
  fetchData(USER_API_ENDPOINTS.notificationCount(langCode));

export const getAntiForgeryToken = async () =>
  fetchData(USER_API_ENDPOINTS.antiForgeryToken());
