import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSupportDocumentation } from "@services";
import Breadcrumb from "@common/Breadcrumb";
import { pathByName } from "../../../helpers/utilities";
import useScrollToHash from "@common/UseScrollToHash/Index";
import toast from "react-hot-toast";

const Documentation = () => {
  const { languageCode } = useSelector((state) => state.languageData);
  const [documentationData, setDocumentationData] = useState({});
  const { translations } = useSelector((state) => state.translations);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSupportDocumentation(languageCode);
        setDocumentationData(data);
      } catch (error) {
        toast.error(
          translations.An_error_occurred_while_processing_your_request,
        );
      }
    };

    fetchData();
  }, [languageCode]);

  useScrollToHash(documentationData);

  return (
    <>
      {documentationData && (
        <>
          <div className="container-full">
            <div className="container myStuff-header">
              <h1>{documentationData.title}</h1>
              <Breadcrumb items={[{ label: documentationData.subtitle }]} />
            </div>
          </div>
          <div className="container">
            <div className="row resources">
              {documentationData.documentsList &&
                documentationData.documentsList.map((item, index) => {
                  const anchor = pathByName(item.title, "documentation");
                  return (
                    <div key={`${item.title}-${index}`}>
                      <h2
                        style={{ marginBottom: 10, marginTop: 45 }}
                        className="anchor-link"
                      >
                        {item.title}
                        <Link
                          id={anchor}
                          className="anchor-link-icon"
                          to={`#${anchor}`}
                          aria-label="Anchor"
                        >
                          {"\u{1F517}"}
                        </Link>
                      </h2>
                      <div className="panel-group">
                        {item.documents &&
                          item.documents.map((content, index) => (
                            <React.Fragment key={content.type + "_" + index}>
                              {content.type && (
                                <div className="panel panel-default">
                                  <div
                                    className="panel-heading"
                                    style={{ paddingLeft: 0 }}
                                  >
                                    <h4
                                      id={content.type}
                                      dangerouslySetInnerHTML={{
                                        __html: content.type,
                                      }}
                                    ></h4>
                                  </div>
                                </div>
                              )}
                              {content.links &&
                                content.links.map((linkItem, index) => (
                                  <div key={`${linkItem.mainUrlText}-${index}`}>
                                    <div className="panel panel-default">
                                      <div
                                        className="resource"
                                        style={{ marginBottom: 20 }}
                                      >
                                        <a
                                          href={linkItem.mainUrl}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {linkItem.mainUrlText}
                                        </a>
                                        <br />
                                        {linkItem.MainUrlTextSecondLine && (
                                          <span className="desc">
                                            {linkItem.MainUrlTextSecondLine}
                                          </span>
                                        )}
                                        <div className="desc">
                                          <span>{linkItem.description}</span>
                                        </div>

                                        {linkItem.availability && (
                                          <>
                                            <br />
                                            {linkItem.availability.title}{" "}
                                            {linkItem.availability.languages.map(
                                              (langItem, index) => (
                                                <React.Fragment
                                                  key={`${langItem.language}-${index}`}
                                                >
                                                  <a
                                                    href={langItem.url}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                  >
                                                    {langItem.language}
                                                  </a>
                                                  {index <
                                                    linkItem.availability
                                                      .languages.length -
                                                      1 && ", "}
                                                </React.Fragment>
                                              ),
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Documentation;
