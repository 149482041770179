import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { RootState } from "store";
import { deleteMessage, getTags, updateMessage } from "@services";
import TableHeader from "./TableHeader/TableHeader";
import TableRow from "./TableRow";
import MultiSelectToolbar from "./TableHeader/MultiSelectToolbar";
import { Language, Message } from "../types";

interface TableProps {
  messages: Message[];
  availableLangs: Language[];
  onMessageSelect: (message: Message) => void;
  onSelectAll: (selectAll: boolean) => void;
  setMessages: (
    messages: Message[] | ((prevMessages: Message[]) => Message[]),
  ) => void;
}

const Table: React.FC<TableProps> = ({
  messages,
  onMessageSelect,
  onSelectAll,
  availableLangs,
  setMessages,
}) => {
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );

  const [bulkAction, setBulkAction] = useState({
    inProgress: false,
    complete: false,
  });
  const [allTags, setAllTags] = useState<string[]>([]);

  const areAllSelected = messages.every((msg) => msg.selected);
  const selectedCount = messages.filter((msg) => msg.selected).length;

  // Convert predefined tags into options for react-select
  const tags = allTags.map((tag) => ({ label: tag, value: tag }));

  useEffect(() => {
    const fetchAllTags = async () => {
      const data = await getTags();
      setAllTags(data || []);
    };
    fetchAllTags();
  }, []);

  const updateMessageState = async (
    messageId: string,
    updateFn: (message: Message) => Message,
  ) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === messageId
          ? {
              ...updateFn(msg),
              lastFailedUpdate: { ...msg },
            }
          : msg,
      ),
    );

    const foundMessage = messages.find((msg) => msg.id === messageId);
    if (!foundMessage) {
      console.error("Message not found for update:", messageId);
      return;
    }

    try {
      const updatedMessage = updateFn(foundMessage);
      await updateMessage(messageId, updatedMessage);
    } catch (error) {
      console.error("Error updating message:", error);
      toast.error(translations.MYTD_saveFailedMsg);

      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === messageId && msg.lastFailedUpdate
            ? {
                ...msg.lastFailedUpdate,
                saveFailed: true,
                lastFailedUpdate: { ...msg.lastFailedUpdate },
              }
            : msg,
        ),
      );
    }
  };

  const onMessageLabelUpdate = (message: Message, newLabel: string) => {
    if (message.label === newLabel || newLabel.trim().length === 0) return;
    updateMessageState(message.id, (msg) => ({ ...msg, label: newLabel }));
  };

  const onMessageTranscriptionTextUpdate = (
    message: Message,
    newTranscription: string,
  ) => {
    if (
      message.transcription.text === newTranscription ||
      newTranscription.trim().length === 0
    )
      return;
    updateMessageState(message.id, (msg) => ({
      ...msg,
      transcription: {
        ...msg.transcription,
        text: newTranscription,
        verified: true,
      },
    }));
  };

  const onConfidenceClick = (message: Message) => {
    if (message.transcription.verified) return;
    updateMessageState(message.id, (msg) => ({
      ...msg,
      transcription: { ...msg.transcription, verified: true },
    }));
  };

  const onTagAdd = (message: Message, tagName: string) => {
    if (message.tags.some((tag) => tag.name === tagName)) return;

    updateMessageState(message.id, (msg) => ({
      ...msg,
      tags: [...msg.tags, { name: tagName }],
    }));
  };

  const onTagDelete = (message: Message, tagName: string) => {
    updateMessageState(message.id, (msg) => ({
      ...msg,
      tags: msg.tags.filter((tag) => tag.name !== tagName),
    }));
  };

  const onSelectedMessagesTagAdd = async (tagNames: string[]) => {
    const selectedMessages = messages.filter((msg) => msg.selected);

    setBulkAction({ inProgress: true, complete: false });

    const failedMessages: Message[] = [];

    await Promise.all(
      selectedMessages.map(async (msg) => {
        const newTags = [
          ...msg.tags,
          ...tagNames
            .filter((name) => !msg.tags.some((tag) => tag.name === name))
            .map((name) => ({ name })),
        ];

        const updatedMessage = { ...msg, tags: newTags };

        setMessages((prevMessages) =>
          prevMessages.map((m) => (m.id === msg.id ? updatedMessage : m)),
        );

        try {
          await updateMessage(msg.id, updatedMessage);
        } catch (error) {
          console.error(`Error adding tags to message ${msg.id}:`, error);
          failedMessages.push(msg);
        }
      }),
    );

    if (failedMessages.length > 0) {
      toast.error(
        `${translations.MYTD_saveFailedMsg} ${translations.For} ${failedMessages.length} ${
          failedMessages.length > 1
            ? translations.MYTD_MBtabLblMessages
            : translations.Message
        }.`,
      );

      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          failedMessages.some((failed) => failed.id === msg.id)
            ? { ...msg }
            : msg,
        ),
      );
    } else {
      toast.success(translations.Changes_saved);
    }

    setBulkAction({ inProgress: false, complete: true });
  };

  const onMessageDelete = async (message: Message) => {
    setBulkAction({ inProgress: true, complete: false });

    setMessages((prevMessages) =>
      prevMessages.filter((msg) => msg.id !== message.id),
    );

    try {
      await deleteMessage(message.id);
      toast.success(translations.Successfully_Deleted);
    } catch (error) {
      console.error(`Error deleting message ${message.id}:`, error);
      toast.error(translations.Unsuccessfully_deleted);
      setMessages((prevMessages) => [...prevMessages, message]);
    }

    setBulkAction({ inProgress: false, complete: true });
  };

  const onSelectedMessagesDelete = async () => {
    const selectedMessages = messages.filter((msg) => msg.selected);

    setBulkAction({ inProgress: true, complete: false });

    const deletedIds = selectedMessages.map((msg) => msg.id);
    setMessages((prevMessages) =>
      prevMessages.filter((msg) => !deletedIds.includes(msg.id)),
    );

    const failedMessages: Message[] = [];

    await Promise.all(
      selectedMessages.map(async (msg) => {
        try {
          await deleteMessage(msg.id);
        } catch (error) {
          console.error(`Error deleting message ${msg.id}:`, error);
          failedMessages.push(msg);
        }
      }),
    );

    if (failedMessages.length > 0) {
      toast.error(
        `${translations.Unsuccessfully_deleted} ${translations.For} ${failedMessages.length} ${failedMessages.length > 1 ? translations.MYTD_MBtabLblMessages : translations.Message}.`,
      );

      setMessages((prevMessages) => [...prevMessages, ...failedMessages]);
    } else {
      toast.success(translations.Successfully_Deleted);
    }

    setBulkAction({ inProgress: false, complete: true });
  };

  const onSelectedMessagesReplaceLabel = async () => {
    const selectedMessages = messages.filter((msg) => msg.selected);

    setBulkAction({ inProgress: true, complete: false });

    const failedMessages: Message[] = [];

    await Promise.all(
      selectedMessages.map(async (msg) => {
        const updatedMessage = { ...msg, label: msg.transcription.text };

        setMessages((prevMessages) =>
          prevMessages.map((m) => (m.id === msg.id ? updatedMessage : m)),
        );

        try {
          await updateMessage(msg.id, updatedMessage);
        } catch (error) {
          console.error(`Error replacing label for message ${msg.id}:`, error);
          failedMessages.push(msg);
        }
      }),
    );

    if (failedMessages.length > 0) {
      toast.error(
        `${translations.MYTD_saveFailedMsg} ${translations.For} ${failedMessages.length} ${
          failedMessages.length > 1
            ? translations.MYTD_MBtabLblMessages
            : translations.Message
        }.`,
      );

      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          failedMessages.some((failed) => failed.id === msg.id)
            ? { ...msg }
            : msg,
        ),
      );
    } else {
      toast.success(translations.Changes_saved);
    }

    setBulkAction({ inProgress: false, complete: true });
  };

  const resetBulkAction = () => {
    setBulkAction({ inProgress: false, complete: false });
  };

  const onSaveRetry = async (message: Message) => {
    if (!message.lastFailedUpdate) return;

    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === message.id ? { ...msg, saveFailed: false } : msg,
      ),
    );

    try {
      await updateMessage(message.id, message.lastFailedUpdate);
      toast.success(translations.MYTD_updateSuccessMsg);

      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === message.id
            ? { ...msg, saveFailed: false, lastFailedUpdate: undefined }
            : msg,
        ),
      );
    } catch (error) {
      console.error("Error retrying save:", error);
      toast.error(translations.MYTD_saveFailedMsg);

      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === message.id ? { ...msg, saveFailed: true } : msg,
        ),
      );
    }
  };

  const onSaveRetryCancel = (
    e: React.MouseEvent<HTMLAnchorElement>,
    message: Message,
  ) => {
    e.preventDefault();

    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === message.id && msg.lastFailedUpdate
          ? {
              ...msg.lastFailedUpdate,
              saveFailed: false,
              lastFailedUpdate: undefined,
            }
          : msg,
      ),
    );
  };

  return (
    <table className="table table-message-list">
      {selectedCount > 0 ? (
        <MultiSelectToolbar
          messages={messages}
          selectedCount={selectedCount}
          onDelete={onSelectedMessagesDelete}
          onTag={onSelectedMessagesTagAdd}
          onReplace={onSelectedMessagesReplaceLabel}
          onSelectAll={onSelectAll}
          tags={tags}
        />
      ) : (
        <TableHeader onSelectAll={onSelectAll} allSelected={areAllSelected} />
      )}
      <tbody>
        {messages.map((message) => (
          <TableRow
            key={message.id}
            message={message}
            onMessageSelect={onMessageSelect}
            onMessageLabelUpdate={onMessageLabelUpdate}
            availableLangs={availableLangs}
            onMessageTranscriptionTextUpdate={onMessageTranscriptionTextUpdate}
            onConfidenceClick={onConfidenceClick}
            onTagAdd={onTagAdd}
            onTagDelete={onTagDelete}
            onMessageDelete={onMessageDelete}
            bulkAction={bulkAction}
            resetBulkAction={resetBulkAction}
            onSaveRetry={onSaveRetry}
            onSaveRetryCancel={onSaveRetryCancel}
            tags={tags}
          />
        ))}
      </tbody>
    </table>
  );
};

export default Table;
