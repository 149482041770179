import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import md5 from "md5";
import toast from "react-hot-toast";
import { RootState } from "store";
import {
  postAddMessage,
  putUploadMessage,
  downloadAllMessages,
} from "@services";
import Translation from "@common/Translation";
import UploadQueueModal from "./UploadQueueModal";
import UploadStatusModal from "./UploadStatusModal";
import { Language } from "./types";
import { FileWithMetadata } from "types";
import ActionModal from "./Table/TableHeader/ActionModal";
import { handleFileDownload } from "./utils/download";

const validFileTypes = ["audio/wav"];

const HeaderButtons: React.FC<{ availableLangs: Language[] }> = ({
  availableLangs,
}) => {
  const { translations } = useSelector(
    (state: RootState) => state.translations,
  );

  const [fileQueue, setFileQueue] = useState<FileWithMetadata[]>([]);
  const [showUploadQueueModal, setShowUploadQueueModal] = useState(false);
  const [showUploadStatusModal, setShowUploadStatusModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [controller, setController] = useState<AbortController | null>(null);

  const validateFiles = (files: File[]) =>
    files.filter((file) => {
      if (!validFileTypes.includes(file.type)) {
        toast.error(
          `${translations.MYTD_fileTypeAllowedMsg} .wav (${file.name})`,
          { duration: 5000 },
        );
        return false;
      }
      return true;
    });

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = validateFiles(Array.from(event.target.files || []));
    if (!files.length) return;

    const newFiles = await Promise.all(
      files.map(async (file) => ({
        file,
        fileContentMd5: md5(new Uint8Array(await file.arrayBuffer())),
        progress: 0,
        status: "pending",
      })),
    );

    setFileQueue((prev) => [...prev, ...newFiles]);
    setShowUploadQueueModal(true);
  };

  const downloadAllFiles = async (cancelToken: AbortSignal) => {
    setLoadingDownload(true);
    setDownloadComplete(false);

    try {
      const response = await downloadAllMessages(cancelToken);
      handleFileDownload(response, "BankedMessages");
      setDownloadComplete(true);
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === "CanceledError") {
          console.warn("Download canceled by user");
        } else {
          console.error("Error downloading all files:", error.message);
          toast.error(
            translations.An_error_occurred_while_processing_your_request,
          );
        }
      } else {
        console.error("Unknown error occurred:", error);
        toast.error(
          translations.An_error_occurred_while_processing_your_request,
        );
      }
    } finally {
      setLoadingDownload(false);
    }
  };

  const handleDownloadStart = useCallback(() => {
    const newController = new AbortController();
    setController(newController);
    downloadAllFiles(newController.signal);
  }, []);

  const handleDownloadCancel = useCallback(() => {
    if (controller) {
      controller.abort();
      setController(null);
    }
    setShowDownloadModal(false);
  }, [controller]);

  const startUpload = async (languageModelId: string) => {
    setShowUploadQueueModal(false);
    setShowUploadStatusModal(true);

    for (const fileMetadata of fileQueue) {
      setFileQueue((prevQueue) =>
        prevQueue.map((item) =>
          item.file === fileMetadata.file
            ? { ...item, status: "uploading" }
            : item,
        ),
      );

      try {
        const response = await postAddMessage(
          {
            fileContentMd5: fileMetadata.fileContentMd5,
            fileName: fileMetadata.file.name,
            languageModelId:
              languageModelId === "noTranscribe" ? null : languageModelId,
            transcribeFileWhenUploaded: languageModelId !== "noTranscribe",
          },
          {
            "X-Upload-Content-Length": fileMetadata.file.size.toString(),
            "X-Upload-Content-Type": fileMetadata.file.type,
          },
        );

        if (!response) throw new Error(translations.MYTD_uploadErrorMsg);

        await putUploadMessage(response, fileMetadata.file);

        setFileQueue((prevQueue) =>
          prevQueue.map((item) =>
            item.file === fileMetadata.file
              ? { ...item, status: "completed", progress: 100 }
              : item,
          ),
        );
      } catch (error) {
        console.error(`Error uploading file ${fileMetadata.file.name}:`, error);
        setFileQueue((prevQueue) =>
          prevQueue.map((item) =>
            item.file === fileMetadata.file
              ? { ...item, status: "failed" }
              : item,
          ),
        );
      }
    }
  };

  const clearQueue = () => {
    setFileQueue([]);
    setShowUploadQueueModal(false);
  };

  return (
    <>
      <div style={{ position: "absolute", right: 0, top: -123 }}>
        <button className="btn btn-primary btn-lg pull-left upload-button">
          <Translation translate="MYTD_uploadLbl" />
          <input
            className="upload-input"
            type="file"
            multiple
            accept=".wav"
            onChange={handleFileChange}
          />
        </button>
        <button
          className="btn btn-primary btn-lg pull-left"
          onClick={() => setShowDownloadModal(true)}
        >
          <Translation translate="MYTD_downloadAllLbl" />
        </button>
      </div>

      {/* Upload Queue Modal */}
      <UploadQueueModal
        showModal={showUploadQueueModal}
        fileQueue={fileQueue}
        availableLangs={availableLangs}
        onCancel={clearQueue}
        onStart={startUpload}
      />

      {/* Upload Status Modal */}
      <UploadStatusModal
        showModal={showUploadStatusModal}
        setShowUploadStatusModal={setShowUploadStatusModal}
        fileQueue={fileQueue}
        queueLength={
          fileQueue.filter((file) => file.status === "uploading").length
        }
        queueTotalSize={fileQueue.length}
        queueTotalFileSize={fileQueue.reduce(
          (sum, file) => sum + file.file.size,
          0,
        )}
        queueTotalFileSizeProgress={fileQueue.reduce(
          (sum, file) => sum + (file.progress / 100) * file.file.size,
          0,
        )}
      />

      {/* Download All Messages Modal */}
      <ActionModal
        show={showDownloadModal}
        title={translations.MYTD_downloadAllLbl}
        body={<p>{translations.MYTD_takesAWhileMsg}</p>}
        confirmText={
          downloadComplete
            ? translations.MYTD_downloadCompleteLbl
            : translations.Download
        }
        cancelText={translations.MYTD_cancelBtnTxt}
        onConfirm={
          downloadComplete
            ? () => setShowDownloadModal(false)
            : handleDownloadStart
        }
        onCancel={handleDownloadCancel}
        loading={loadingDownload}
      />
    </>
  );
};

export default HeaderButtons;
